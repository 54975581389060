import React from "react";
import { withRouter } from "react-router-dom";

const AuthVerify = (props) => {  
  props.history.listen(() => {
    //console.log("history listen")
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const {exp} = JSON.parse(atob(user.accessToken.split('.')[1]));
      //console.log(new Date(exp*1000))  //expire time
      if ((exp*1000) < Date.now()) {
        props.logOut();
      }
    }
  })

  return <div></div>;
};

export default withRouter(AuthVerify);
