import axios from "axios";
import { API_url } from '../config/config'
import { logout } from "./auth";

export const showUsertoHR =(email,code) =>async (dispatch, getState) => {
        try {
          const {
            auth: { user },
          } = getState();
      
          //Header to send with the request
          const config = {
            headers: {
              'x-access-token': `${user.accessToken}`
            },
          }; 
          const { data } = await axios.post(`${API_url}/api/showUser`, {email,code}, config);
        // console.log(data)
          return data;
        } catch (error) {
          if(error.response && error.response.status === 401){
            dispatch(logout());
          }
          return error.response ? error.response : error;
        }
      };
      
      export const getOneUserMode = (email) => async (dispatch, getState) => {
       // console.log("get one user mode calıstı")
        try {
          const {
            auth: { user },
          } = getState();
      
          //Header to send with the request
          const config = {
            headers: {
              'x-access-token': `${user.accessToken}`
            },
          }; 
          const { data } = await axios.get(`${API_url}/api/modUser/${email}`, config);
         //console.log(data)
         return data;
        } catch (error) {
          if(error.response && error.response.status === 401){
            dispatch(logout());
          }
          return error.response ? error.response : error;
        }
      };


  // export const getAllUsersMode = (page, searchValue, status) => async (dispatch, getState) => {
  //   console.log("get all users operator calıstı ==>", page, searchValue, status)
  //   let searchStatus = status === "true" ? true : false;
  //   //console.log(searchStatus)
  //   try {
  //     const {
  //       auth: { user },
  //     } = getState();
  
  //     //Header to send with the request
  //     const config = {
  //       headers: {
  //         'x-access-token': `${user.accessToken}`,
  //       },
  //     };
  //    const searchQuery = {
  //       "filter":searchValue,
  //       "status":searchStatus,
  //       "showOperator":true,
  //       "limit":10,
  //       "skip":10*(page-1)
  //   }
  //     const { data } = await axios.post(`${API_url}/api/allUsers`, searchQuery, config );
  //     //console.log(data)
  //     dispatch({
  //       type: USER_MODE_SUCCESS,
  //       payload: data.data,
  //     });
  //     return data.meta;
  //   } catch (error) {
    // if(error.response && error.response.status === 401){
    //   dispatch(logout());
    // }
  //     dispatch({
  //       type: USER_ALL_FAIL,
  //       payload: error.response && error.response.data.message,
  //     });
  //     return error;
  //   }
  // };
