import React, { useState, useEffect } from "react";
import { API_url } from "../config/config";

// Redux
import { useDispatch } from "react-redux";
// Bootstrap Components
import {
  InputGroup,
  FormControl,
  Card,
  ListGroupItem,
  Alert,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
} from "react-bootstrap";
import { getOneUserMode } from "../actions/supervisor.action";
import { sendUsertoHR, changeUserVisible, getAllUsers } from "../actions/admin.action";
import { downloadHRDocument } from "../actions/document.action";


const BoardSupervisor = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState({});
  const [downloadURL, setdownloadURL] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [HRmail, setHRmail] = useState("");
  const [sendMessage, setsendMessage] = useState("");

  const [copySuccess, setCopySuccess] = useState("");
  const [message, setMessage] = useState("");
  const cvReference = React.createRef();
  const codeAreaRef = React.createRef();

  useEffect(() => {
    if (state && state.userDocuments && state.userDocuments.length > 0) {
      dispatch(downloadHRDocument(state.userDocuments[0].url)).then((res) => {
        const objectURL = window.URL.createObjectURL(res);
        setdownloadURL(objectURL);
      });
    }
  }, [dispatch, state]);

  const onFilterPageClear = (e) => {
    setsearchValue("");
    setstate({});
  };

  const handleInput = (e) => {
    setsearchValue(e.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    if(!searchValue){
      return;
    }
    dispatch(getOneUserMode(searchValue)).then((res) => {
     // console.log(res);
      if (res.status === 200) {
        setstate(res && res.user && res.user[0]);
        if(res && res.user && res.user.length === 0){
          setMessage("Please enter a validate mail and try again please!");
        }else {
          setsearchValue("");
        }
      } else if (res.status === 400) {
        setMessage(res.data.msg);
      }
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  };

  const handleSendHR = (id) => {
    dispatch(sendUsertoHR(id, HRmail)).then((res) => {
      if (res.status === 200) {
        setsendMessage(res.message);
        setHRmail("");
        setTimeout(() => {
          setsendMessage("");
        }, 3000);
      }
    });
  };

  function copyToClipboard(e) {
    codeAreaRef.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 2000);
  }

  const handleChangeVisible = (id) => {
    dispatch(changeUserVisible(id)).then((res) => {
      if (res.status === 200) {
        setMessage(res.msg);
        setTimeout(() => {
          setMessage("");
        }, 2000);
        dispatch(getAllUsers(1, "", true));
      }
    });
  };

  //console.log(rolesAll);
  //console.log(status);
  //console.log(selected);
  //console.log(selectedRole);

  return (
    <div>
      <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <div className="container mt-5">
        <h2 className="text-center para-light" style={{ color: "white" }}>
          User Search
        </h2>
        </div>
        <Col lg={6} style={{ margin: "0 auto" }}>
          <Form onSubmit={handleSearch}>
            <InputGroup className="mb-2">
              <InputGroup.Text
                onClick={handleSearch}
                style={{ cursor: "pointer" }}
                title="Search"
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
              <FormControl
                id="userAllSearch"
                type="text"
                placeholder="Search with email..."
                name="searchValue"
                value={searchValue}
                onChange={handleInput}
              />
              <InputGroup.Text
                onClick={onFilterPageClear}
                style={{ cursor: "pointer" }}
                title="Clear"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </InputGroup.Text>
            </InputGroup>
          </Form>
          {message &&
          <Alert variant="warning" >{message}</Alert>
          }
        </Col>
        {state && state._id && (
          <Card id="supCard">
          <Card.Img
            variant="top"
            className="mx-auto rounded-circle"
            style={{ height: 100, width: 100 }}
            //src="https://www.w3schools.com/howto/img_avatar.png"
            src={`${API_url}/api/file/${state.imgUrl}`}
          />
          <Card.Title className="mx-auto">
            {`${
              state &&
              state.firstname.charAt(0).toUpperCase() +
                state.firstname.slice(1)
            } 
            ${
              state &&
              state.middlename.charAt(0).toUpperCase() +
                state.middlename.slice(1)
            }
            ${
              state &&
              state.lastname.charAt(0).toUpperCase() +
                state.lastname.slice(1)
            }`}
          </Card.Title>
          {state ?
            state.userContracts.map((item, index) => (
              <Card.Body key={index}>
                <Row>
                  <Col sm={4}>
                    <Card.Title style={{ fontSize: "18px" }}>
                      <u> {item.company}</u>
                    </Card.Title>
                  </Col>
                  <Col sm={8}>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>
                        <span>Position: </span>
                        {item.position}
                      </ListGroupItem>
                      <ListGroupItem>
                        <span>Start Date: </span>
                        {item.hireDate &&
                          new Date(item.hireDate).toLocaleDateString()}
                      </ListGroupItem>
                      <ListGroupItem>
                        <span>End Date:</span>
                        {item.endDate
                          ? new Date(item.endDate).toLocaleDateString()
                          : " In progress..."}
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </Card.Body>
            ))
            :
            <Alert variant="info">There is no active contract</Alert>
          }
          {state &&
            state.userDocuments &&
            state.userDocuments.length > 0 && (
              <Card.Body>
                <a
                  ref={cvReference}
                  href={downloadURL}
                  target="_blank"
                  className="text-center"
                  // download={state.firstname + "_" + state.lastname + "_resume"}
                >
                  <Button
                    size="sm"
                    type="button"
                    variant=" btn2 success d-flex"
                    className="mx-auto d-flex"
                  >
                    Open Resume
                  </Button>
                </a>
              </Card.Body>
            )}
            <Card.Body>
              <Row>
            <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>User Code</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span className="d-flex align-items-baseline">
                    <i className="fas fa-key"></i>
                    {"  "}
                    <textarea
                      ref={codeAreaRef}
                      value={state.code}
                      cols="38"
                      rows="1"
                      style={{
                        outline: "none",
                        border: "none",
                        resize: "none",
                      }}
                      readOnly
                    />{" "}
                    <i
                      className="fas fa-copy"
                      onClick={copyToClipboard}
                      title="Copy to clipboard!"
                      style={{ cursor: "pointer" }}
                    ></i>
                    {copySuccess}
                  </span>
                </ListGroup>
                <ListGroup variant="flush">
                  <Form.Label className="col-lg-8" column="sm">Send user mail and code to a HR Operator</Form.Label>
                  {sendMessage && <Form.Label className="col-lg-6" column="sm" style={{color:"green"}}><i className="fas fa-check-circle"></i>{sendMessage}</Form.Label>}
                <InputGroup className="mb-2 col-lg-6" >
                  <FormControl
                    id="sendCode"
                    type="text"
                    placeholder="Write HR mail address here..."
                    name="HRmail"
                    value={HRmail}
                    onChange={(e)=>setHRmail(e.target.value)}
                    size="sm"
                  />
                  <InputGroup.Text size="sm"
                    onClick={()=>handleSendHR(state._id)}
                    style={{ cursor: "pointer" }}
                    title="Clear"
                    >
                    Send
                  </InputGroup.Text>
                </InputGroup>
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Open HR Search</span>
              </Col>
              <Col sm={9} className="d-flex align-items-end">
              <hr className="mt-1"></hr>
                <Form.Check
                      // label={item.showOperator? "Yes" : "No"}
                      name="showOperator"
                      type="switch"
                      title="Click to change HR visibility"
                      defaultChecked={state.showOperator}
                      onClick={() => handleChangeVisible(state._id)}
                    />
                </Col>
              </Row>
            </Card.Body>
        </Card>
        )}
      </div>
    </div>
  );
};

export default BoardSupervisor;
