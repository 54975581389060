import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  Form,
  Button,
  Modal,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import {
  getUserDocument,
  addNewDocument,
  updateUserDocument,
} from "../actions/document.action";
import UserDocumentDetail from "./UserDocumentDetail";

const initialDocument = {
  name: "",
  file: "",
  downloadHr: false,
};

export default function UserDocument(props) {
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const { current } = useSelector((store) => store.documents);
  const [showModal, setshowModal] = useState(false);
  const [newdocument, setnewdocument] = useState(initialDocument);
  const [uploadMessage, setuploadMessage] = useState("");
  const inputReferenceNew = React.createRef();
  const [show, setshow] = useState("");
  const [warnWidth, setwarnWidth] = useState("Enter document name please");
  const [visibleDocument, setvisibleDocument] = useState({})

  const [alert, setalert] = useState("");
  const visibleDoc = current && current.find((item) => item.downloadHr === true);
  console.log(visibleDoc)
  
  useEffect(() => {
    dispatch(getUserDocument());
  }, [dispatch]);

  useEffect(() => {
    if (visibleDoc) {
      setvisibleDocument(visibleDoc);
    }
  }, [visibleDoc]);

  useEffect(() => {
    if (current) {
      setstate(current);
    }
  }, [current]);

  const handleInput = (e) => {
   // console.log(e.target.value.length);
    setnewdocument({ ...newdocument, [e.target.name]: e.target.value });
    if (e.target.value) {
      setwarnWidth("");
    } else if (!e.target.value || e.target.value.length === 0) {
      setwarnWidth("Enter document name please");
    } else {
      setwarnWidth("Enter document name please");
    }
  };

  const fileUploadAction = () => inputReferenceNew.current.click();

  const fileUploadInputChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].type !== "application/pdf") {
      setalert("Please select Pdf files only!");
      return;
    }
    if (e.target.files[0].size >= 2000000) {
      setalert("Please select file size under 2Mb!");
      return;
    }
    setnewdocument({ ...newdocument, file: e.target.files[0] });
    setalert("");
    setuploadMessage("File selected succesfully!");
  };

  const handleAddDocument = () => {
    const file = new FormData();
    if (newdocument.file.name) {
      file.append("file", newdocument.file);
    }
    file.append("name", newdocument.name);
    file.append("downloadHr", newdocument.downloadHr);
    if (!newdocument.name) {
      setalert("Please give a name for your document!");
      return;
    }
    dispatch(addNewDocument(file)).then((data) => {
      if (data.status === 200) {
        setshowModal(false);
        setshow(data.message);
        setwarnWidth("Enter document name please");
        setuploadMessage("");
        setalert("");
        setnewdocument(initialDocument);
        setTimeout(() => {
          setshow("");
          dispatch(getUserDocument());
        }, 2500);
      } else {
        setuploadMessage("");
        setalert(data.message);
        setTimeout(() => {
          setalert("");
        }, 2500);
      }
    });
  };

  const handleChangeShow = () => {
    const visible = current && current.find((item) => item.downloadHr === true);
   // console.log(visible);
    if (visible) {
      const file = new FormData();
      file.append("name", visible.name);
      file.append("downloadHr", false);
      dispatch(updateUserDocument(file, visible._id)).then((data) => {
        if (data.status === 200) {
          console.log("diğer document update başarılı")
          dispatch(getUserDocument());
        }
      });
    }
  };

  const handleCancel = () => {
    setshowModal(false);
    setnewdocument(initialDocument);
    setuploadMessage("");
    setalert("");
  };

  //console.log(newdocument);
  //console.log(warnWidth);
  return (
    <div className="pl-3 pr-3">
      <div className="editButtonGroup p-0 m-0 ">
        <Button
          size="sm"
          variant="fw-bolder fs-7 me-2 py-2 px-3"
          className="position-relative btn1"
          style={{ top: "0px" }}
          onClick={() => setshowModal(!showModal)}
        >
          Add Document
        </Button>
      </div>
      {show && (
        <div className="d-flex justify-content-center">
          <Alert className="p-2 text-center w-50" variant="success">
            {show}
          </Alert>
        </div>
      )}
      <Modal
        show={showModal}
        onHide={handleCancel}
        color="danger"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2 col">
            <InputGroup size="sm">
              <InputGroup.Text column="sm">Document Name</InputGroup.Text>
              <Form.Control
                onChange={handleInput}
                name="name"
                size="sm"
                type="text"
                value={newdocument.name}
                style={{ borderColor: `${warnWidth && "red"}` }}
              />
            </InputGroup>
            {warnWidth && (
              <InputGroup size="sm" className="justify-content-end">
                <Form.Text type="invalid" style={{ color: "red" }}>
                  {warnWidth}
                </Form.Text>
              </InputGroup>
            )}
          </Form.Group>
          <Form.Group className="mb-2 col">
            <div className="d-flex align-items-end">
              <Form.Control
                type="file"
                hidden
                accept="*"
                ref={inputReferenceNew}
                onChange={fileUploadInputChange}
              />
              <span
                onClick={fileUploadAction}
                className="p-2"
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fa fa-file-upload fa-4x bg-primary text-xpr rounded"
                  aria-hidden="true"
                ></i>
                <span className="ms-2 fw-bolder">Upload</span>
              </span>
              {(alert || uploadMessage) && (
                <Alert
                  className="p-2 text-end"
                  variant={`${alert ? "warning" : "success"}`}
                >
                  {alert}
                  {uploadMessage}
                </Alert>
              )}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {!uploadMessage || !newdocument.name ? (
            <OverlayTrigger
              //notes={product.notes}
              overlay={
                <Tooltip id="tooltip-right">
                  {uploadMessage && !newdocument.name
                    ? "Please write your document name"
                    : !uploadMessage && newdocument.name
                    ? "Please choose a file to upload"
                    : "Please choose a file and name it."}
                </Tooltip>
              }
            >
              <i
                style={{ color: "red" }}
                className="fas fa-exclamation-triangle"
              ></i>
            </OverlayTrigger>
          ) : (
            ""
          )}
          <Button
            variant="fw-bolder fs-7 me-2 py-2 px-3"
            className="btn1"
            onClick={handleAddDocument}
            disabled={uploadMessage && newdocument.name ? false : true}
          >
            Submit{" "}
          </Button>{" "}
          <Button
            variant="fw-bolder fs-7 me-2 py-2 px-3"
            className="btn1"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {state.map((document, index) => (
        <div key={document._id}>
          <UserDocumentDetail
            document={document}
            handleChangeShow={handleChangeShow}
            visibleDocument={visibleDocument}
            {...props}
          /> 
        </div>
      ))}
    </div>
  );
}
