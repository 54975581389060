import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Modal,
  Button,
  InputGroup,
  Alert,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  updateUserDocument,
  deleteUserDocument,
  getUserDocument,
  downloadDocument,
} from "../actions/document.action";

const initialState = {
  _id: "",
  user_id: "",
  name: "",
  type: "",
  url: "",
  size: "",
  downloadHr: false,
};

export default function UserDocumentDetail({
  document,
  handleChangeShow,
  visibleDocument,
}) {
  const dispatch = useDispatch();
  const [isDocumenting, setisDocumenting] = useState(false);
  const [state, setstate] = useState(initialState);
  const [newFile, setnewFile] = useState({});
  const [deleteMessage, setdeleteMessage] = useState("");
  const [deletealert, setdeletealert] = useState("");
  const [uploadMessage, setuploadMessage] = useState("");
  const [alert, setalert] = useState("");
  const inputReference = React.createRef();
  const linkReference = React.createRef();
  const [danger, setWarning] = useState(false);
  const [downloadURL, setdownloadURL] = useState("");
  const [warnWidth, setwarnWidth] = useState("");

  console.log(visibleDocument);
  useEffect(() => {
    if (document) {
      setstate(document);
    }
  }, [document, visibleDocument]);

  useEffect(() => {
    //console.log("get document from details calıstı", state.url)
    dispatch(downloadDocument(state.url)).then((res) => {
      const objectURL = window.URL.createObjectURL(res);
      setdownloadURL(objectURL);
    });
  }, [dispatch, state.url]);

  const selectType = () => {
    let file_type = "";
    if (state.type) {
      if (state.type.substr(-4).substring(0, 1) === "/") {
        file_type = state.type.substr(-3);
      } else {
        file_type = state.type.substr(-4);
      }
    }
    //console.log(file_type);
    return file_type ===
      ("jpeg" ||
        "jpg" ||
        "png" ||
        "bmp" ||
        "gif" ||
        "tiff" ||
        "psd" ||
        "al" ||
        "indd" ||
        "raw")
      ? "-image"
      : file_type === ("doc" || "docx")
      ? "-word"
      : file_type === ("xls" || "xlsx")
      ? "-excel"
      : file_type === ("ppt" || "pptx")
      ? "-powerpoint"
      : file_type === "txt"
      ? "-alt"
      : file_type === "pdf"
      ? "-pdf"
      : "";
  };

  const fileUploadAction = () => inputReference.current.click();

  const fileUploadInputChange = (e) => {
    //console.log(e)
    if (e.target.files[0].type !== "application/pdf") {
      setalert("Please select Pdf files only!");
      return;
    }
    if (e.target.files[0].size >= 2000000) {
      setalert("Please select file size under 2Mb!");
      return;
    }
    setnewFile(e.target.files[0]);
    setalert("");
    setuploadMessage("New file selected succesfully!");
  };

  const handleEditing = () => {
    setisDocumenting(!isDocumenting);
  };

  const handleClearEdit = () => {
    setisDocumenting(false);
    setstate(document);
    setalert("");
    setwarnWidth("");
    setuploadMessage("");
  };

  const handleInput = (e) => {
    if (!e.target.value || e.target.value.length === 0) {
      setwarnWidth("Enter document name please");
    } else {
      setwarnWidth("");
    }
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleChangeHrStatus = (e) => {
    console.log(state.downloadHr);
    const file = new FormData();
    file.append("name", state.name);
    file.append("downloadHr", !state.downloadHr);
    dispatch(updateUserDocument(file, state._id)).then((data) => {
      if (data.status === 200) {
        setstate({ ...state, [e.target.name]: e.target.value });
        console.log("document update başarılı");
        dispatch(getUserDocument());
        handleChangeShow();
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.name) {
      setwarnWidth("Enter document name please");
      return;
    }

    if (!newFile.name && state.name === document.name) {
      setalert("Please make changes for update");
      return;
    }

    const file = new FormData();
    if (newFile.name) {
      file.append("file", newFile);
    }
    file.append("name", state.name);
    file.append("downloadHr", state.downloadHr);

    dispatch(updateUserDocument(file, state._id)).then((data) => {
      if (data.status === 200) {
        setisDocumenting(false);
        setuploadMessage(data.message);
        setTimeout(() => {
          setuploadMessage("");
          dispatch(getUserDocument());
        }, 2800);
      } else {
        setalert(data.message);
        setTimeout(() => {
          setalert("");
        }, 2800);
      }
    });
  };

  const handleDelete = () => {
    //  console.log(state._id);
    dispatch(deleteUserDocument(state._id)).then((data) => {
      // console.log(status);
      if (data.status === 200) {
        setdeleteMessage(data.message);
        setTimeout(() => {
          setWarning(false);
          setdeleteMessage("");
          dispatch(getUserDocument());
        }, 2800);
      } else {
        setdeletealert(data.message);
        setTimeout(() => {
          setalert("");
        }, 2800);
      }
    });
  };

  //console.log(newFile);
  //console.log(state);
  return (
    <div className="row mt-3 pb-2 border-bottom">
      <div
        className="col-3 text-muted d-flex align-items-center justify-content-center"
        style={{ fontSize: 18, fontWeight: 500 }}
      >
        {!isDocumenting && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => linkReference.current.click()}
            title={state.url.split("-")[state.url.split("-").length - 1]}
          >
            <i className={`far fa-file${selectType()} fa-3x text-xpr`}></i>
            <span className="ms-2 fw-bolder">
              <i className="fas fa-download"></i>
            </span>
          </span>
        )}
        <a
          href={downloadURL}
          ref={linkReference}
          download={state.url.split("-")[state.url.split("-").length - 1]}
        ></a>
        <Form.Control
          type="file"
          hidden
          accept="*"
          ref={inputReference}
          onChange={fileUploadInputChange}
        />
        <span
          onClick={fileUploadAction}
          className={`${!isDocumenting ? "d-none" : "p-2"}`}
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-file-upload fa-2x" aria-hidden="true"></i>
          Upload
        </span>
      </div>
      <div
        className="col-9 text-muted text-left"
        style={{ fontSize: 14, fontWeight: 500 }}
      >
        <Form onSubmit={handleSubmit}>
          <Row className="mb-2 d-flex align-items-center ">
            <Form.Group className="mb-2 col-lg-6">
              <InputGroup size="sm">
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="name"
                  size="sm"
                  type="text"
                  value={state.name}
                  disabled={!isDocumenting}
                  style={{ borderColor: `${warnWidth && "red"}` }}
                />
              </InputGroup>
              {warnWidth && (
                <InputGroup size="sm" className="justify-content-end">
                  <Form.Text type="invalid" style={{ color: "red" }}>
                    {warnWidth}
                  </Form.Text>
                </InputGroup>
              )}
            </Form.Group>

            <div className="mb-2 col-lg-6 d-flex justify-content-start">
              <div className="editButtonGroup my-2 p-1">
                <Button
                  size="sm"
                  variant=" fw-bolder fs-7 me-2 py-2 px-3"
                  className={`${!isDocumenting ? "d-none" : "btn1"}`}
                  onClick={handleClearEdit}
                >
                  Discard
                </Button>
                <Button
                  size="sm"
                  variant="fw-bolder fs-7 me-2 py-2 px-2"
                  className={`${isDocumenting ? "d-none" : "btn1"}`}
                  onClick={() => setWarning(!danger)}
                >
                  Delete
                </Button>
                <Modal
                show={danger}
                onHide={() => setWarning(false)}
                color="danger"
                backdrop="static"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure delete selected contract?</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="fw-bolder fs-7 me-2 py-2 px-3"
                    className="btn1"
                    onClick={handleDelete}
                    disabled={deleteMessage ? true : false}
                  >
                    Delete{" "}
                  </Button>{" "}
                  <Button
                    variant="fw-bolder fs-7 me-2 py-2 px-3"
                    className="btn1"
                    onClick={() => setWarning(!danger)}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
                {(deletealert || deleteMessage) && (
                  <div>
                    <Alert
                      className="p-2 text-center"
                      variant={`${deletealert ? "warning" : "success"}`}
                    >
                      {deletealert}
                      {deleteMessage}
                    </Alert>
                  </div>
                )}
              </Modal>
                <Button
                  size="sm"
                  variant="fw-bolder fs-7 me-2 py-2 px-3"
                  type={isDocumenting ? "submit" : "button"}
                  onClick={isDocumenting ? () => {} : handleEditing}
                  className={"btn1"}
                  disabled={
                    isDocumenting &&
                    !newFile.name &&
                    state.name === document.name &&
                    state.downloadHr === document.downloadHr
                      ? true
                      : false
                  }
                >
                  {isDocumenting ? "Save" : "Edit"}
                </Button>
              </div>
              <Form.Group className="mb-2 ">
                <Form.Label htmlFor="downloadHr" className="mb-0 text-muted">
                  HR View{" "}
                  {!state.downloadHr ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          You can select one file for HR.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <i
                          {...triggerHandler}
                          ref={ref}
                          className="fa fa-question-circle"
                          aria-hidden="true"
                        ></i>
                      )}
                    </OverlayTrigger>
                  )
                :
                <i className="fas fa-check-circle" style={{ color: "green" }}></i>
                        }
                </Form.Label>
                <Badge
                  bg={
                    document && document.downloadHr === true
                      ? "success"
                      : "secondary"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={handleChangeHrStatus}
                >
                  {document && document.downloadHr === true ? "Selected" : "Select"}
                </Badge>
            </Form.Group>
            </div>
          </Row>
        </Form>
        {(alert || uploadMessage) && (
          <Alert
            className="p-2 text-center w-50"
            variant={`${alert ? "warning" : "success"}`}
          >
            {alert}
            {uploadMessage}
          </Alert>
        )}
      </div>
    </div>
  );
}
