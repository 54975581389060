import axios from "axios";

import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from "./types";

import { API_url } from "../config/config";

export const ContactFormSubmit = (state) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${API_url}/api/contactform`, state);
    //console.log(data);
    dispatch({
      type: SET_MESSAGE,
      payload: data && data.message,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
      });
    }, 3000);
    return data;
  } catch (error) {
    dispatch({
      type: SET_MESSAGE,
      payload: error.response && error.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
      });
    }, 3000);
  }
};
