import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

// Redux
import { useDispatch, useSelector } from "react-redux";
// Bootstrap Components
import {
  InputGroup,
  FormControl,
  Alert,
  Table,
  Modal,
  Row,
  Col,
  ListGroup,
  Button,
} from "react-bootstrap";
import { getAllDocuments, getOneUser } from "../actions/admin.action";
import { downloadDocument } from "../actions/document.action";

import Pagination from "./Pagination";

const AllFile = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [selected, setselected] = useState({});
  const [selectedUser, setselectedUser] = useState({});
  const [show, setShow] = useState(false);
  const [message, setmessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { documents } = useSelector((store) => store.admins);
  const [downloadURL, setdownloadURL] = useState("")
  const linkReference = useRef(null);

  const handleClose = () => setShow(false);
  const handleCloseX = () => setmessage("");

  useEffect(() => {
    dispatch(getAllDocuments(currentPage, searchValue)).then((data) =>{
      if(data.status === 200){
        settotalItems(data.total)
      }  
    }
    );
  }, [dispatch, currentPage, searchValue]);

  useEffect(() => {
    if (documents) {
      setstate(documents);
    }
  }, [documents]);

  const onChangePage = (pageNum) => {
    setcurrentPage(pageNum);
  };

  const onFilterPageClear = (e) => {
    setsearchValue("");
  };

  function onRowClick(item) {
    dispatch(getOneUser(item.user_id))
    .then(data => {
      if(data.status === 200) {
        setselectedUser(data.user[0])
      }
    })
    const selecteddocument = documents && documents.find((elem) => elem._id === item._id);
    setselected(selecteddocument);
    setShow(true);
  }

  const handleSearch = (e) => {
    setsearchValue(e.target.value);
  };

  const handleDownload = (item) => {
   // console.log("get document from all files calıstı", item)
    dispatch(downloadDocument(item.url))
    .then((res) => {
      const objectURL = window.URL.createObjectURL(res) 
      setdownloadURL(objectURL);
      //linkReference.current.href=objectURL;
      linkReference.current.click()
    })
  }
  //console.log(state);
  // console.log(selected);
  // console.log(selectedUser);
  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Col lg={6}>
        <InputGroup className="mb-2" >
          <InputGroup.Text>
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
          <FormControl
            id="documentAllSearch"
            type="text"
            placeholder="Search Document with names..."
            name="searchValue"
            value={searchValue}
            onChange={handleSearch}
            size="sm"
          />
          <InputGroup.Text
            onClick={onFilterPageClear}
            style={{ cursor: "pointer" }}
            title="Clear"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped bordered hover responsive>
        <thead>
          <tr style={{fontWeight:"bolder"}}>
            <th>Document Name</th>
            <th>HR Visible</th>
            {!isMobile && (
              <>
                <th>Created Date</th>
                <th>Size</th>
                <th>Type</th>
              </>
            )}
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {state ? (
            state.map((item, index) => {
              return (
                <tr key={item._id}  >
                  <td onClick={() => onRowClick(item)}>{item.name}</td>
                  <td onClick={() => onRowClick(item)}>{item.downloadHr ? "Yes" : "No"}</td>
                  {!isMobile && (
                    <>
                      <td onClick={() => onRowClick(item)}>
                  {item.createdAt
                    ? new Date(item.createdAt).toLocaleDateString()
                    : ""}
                    </td>
                    <td onClick={() => onRowClick(item)}>{item.size}</td>
                    <td onClick={() => onRowClick(item)}>{item.type}</td>
                    </>
                  )}
                    <td>
                    <span style={{ cursor: "pointer" }} onClick={() => handleDownload(item)}>
                      <i className="fas fa-download"></i>
                      </span>
                      <a href={downloadURL} ref={linkReference} download></a>
                    </td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {selected ? (
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><Alert variant="success">Document Details</Alert></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <span>Document Name</span>
              </Col>
              <Col sm={8}>
                <ListGroup variant="flush">
                <span><i className="fas fa-file-signature"></i>{" "}{selected.name}</span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Size</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-save"></i>{" "}{selected.size}</span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Type</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-file-alt"></i>{" "}{selected.type}</span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>User Name</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-user"></i>{" "}{selectedUser && selectedUser._id && selectedUser.firstname + " " + selectedUser.middlename + " " + selectedUser.lastname}</span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Download Link</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-file-download"></i>{" "}{selected.url}</span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Create Date</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-hourglass-start"></i>{" "}
                {selected.createdAt
                    ? new Date(selected.createdAt).toLocaleDateString()
                    : ""}
                    </span>
              </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Update Date</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                <span><i className="fas fa-hourglass-end"></i>{" "}
                  {selected.updatedAt
                    ? new Date(selected.updatedAt).toLocaleDateString()
                    : ""}
                </span>
                </ListGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div>
              {/* <Button
                variant="danger"
                className="p-1 btn2"
                size={isMobile && "sm"}
              >
                Delete
              </Button> */}
            </div>
            <div>
              <Button
                variant="secondary"
                className="p-1 btn2"
                size={isMobile && "sm"}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      <Modal show={message ? true : false} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success mt-2" role="alert">
            {message}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllFile;
