import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Tabs, Tab } from "react-bootstrap";

import { getUserContract } from "../actions/contract.action";
import { getOneUser } from "../actions/user.action";
import UserPersonal from "../components/UserPersonal";
import UserContract from "../components/UserContract";
import UserDocument from "../components/UserDocument";

const BoardUser = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOneUser())
    dispatch(getUserContract());
  }, [dispatch])

  return (
    <div
      className="card shadow p-3 mt-3 boarduser"
      style={{
        maxWidth: "1024px",
        margin: "0 auto",
      }}
    >
      <div>
        <Tabs defaultActiveKey="personal" id="user-details" className=" mb-3">
          <Tab eventKey="personal" title="Personal">
            <UserPersonal {...props}/>
          </Tab>
          <Tab eventKey="contract" title="Contract">
            <UserContract {...props}/>
          </Tab>
          <Tab eventKey="contact" title="Files">
            <UserDocument  {...props}/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default BoardUser;
