import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {ContactFormSubmit} from "../actions/form.action"
import { Alert} from "react-bootstrap";
const initialcontactForm = {
  name:"",
  phone:"",
  email:"",
  query:"",
}


const HomePage = () => {
  const dispatch = useDispatch();
  const {message} = useSelector(state => state)
  const [contactForm, setcontactForm] = useState(initialcontactForm)
  const [visible, setVisible] = useState(false)

const handleFormInput = (e) => {
  if(e.target.name === "phone"){
    var phone = e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    setcontactForm({ ...contactForm, [e.target.name]: phone });
  } else {
    setcontactForm({ ...contactForm, [e.target.name]: e.target.value });
  }
}

const handleFormSubmit = (e) => {
  e.preventDefault();
  dispatch(ContactFormSubmit(contactForm))
  .then(res=>{
    if(res.status===200){
      handleFormClear();  
    }
  })
}

const handleFormClear = (e) => {
  setcontactForm(initialcontactForm);
}

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

//console.log("message ==>", message)
//console.log(contactForm)
  return (
    <div>
      <section
        className="home py-5 d-flex align-items-center"
        id="header"
        style={{ backgroundImage: "url(images/home.jpg" }}
      >
        <div className="container text-light py-5" data-aos="fade-right">
          <h1 className="headline">
            Best <span className="home_text">IT Solutions</span>
            <br />
            For Your Company
          </h1>
          <p className="para para-light py-3">
          As XPR Soft, we prioritize customer satisfaction and believe we can help strengthen your ecosystem by promoting top talent for your specific needs to fill the talent gap in your companies, and by offering you innovative technological solutions.
          </p>
          <div className="d-flex align-items-center">
            <p className="p-2">
              <i className="fas fa-laptop-house fa-2x"></i>
            </p>
            <p>Web Development</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="p-2">
              <i className="fab fa-salesforce fa-2x"></i>
            </p>
            <p>SalesForce Service</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="p-2">
              <i className="fas fa-users fa-2x"></i>
            </p>
            <p>Staffing Solutions</p>
          </div>
          <div className="my-3">
            <a className="btn" href="#services">
              View Services
            </a>
          </div>
        </div>
      </section>

      <section className="information">
        <div className="container-fluid">
          <div className="row text-light">
            <div className="col-lg-4 text-center p-5" data-aos="zoom-in">
              <i className="fas fa-tachometer-alt fa-3x p-2"></i>
              <h4 className="py-3">Fast Reliable</h4>
              <p className="para-light">
              XPR Soft develops safe, stable, scalable solutions for customers
              in many sectors, including Real Estate, Education, Health and
              E-Commerce. We believe in learning and listening all the time. We
              always believe in thinking about how we can grow ourselves and the
              team and make it better.
              </p>
            </div>
            <div className="col-lg-4 text-center p-5" data-aos="zoom-in">
              <i className="fas fa-headset fa-3x p-2"></i>
              <h4 className="py-3">Customer Oriented</h4>
              <p className="para-light">
              We are here to take care of our employees and customers. We always
              consider each other's interests and try to do what is right for
              our team and customers. We believe in going above and beyond for
              our customers.
              </p>
            </div>
            <div className="col-lg-4 text-center p-5 text-dark" data-aos="zoom-in">
              <i className="fas fa-cubes fa-3x p-2"></i>
              <h4 className="py-3">Our Solutions</h4>
              <p className="para-light">
              Custom apps that work perfectly in all popular browsers and
              provide seamless UX with a clear logical layout and mobile
              adaptation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about d-flex align-items-center text-light py-5"
        id="about"
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-7" data-aos="fade-right">
              <p>ABOUT US</p>
              <h1>
              XPR Soft Staffing and Technology Solutions
              </h1>
              <p className="py-2 para-light">
              XPR Soft is driven to provide you the best consultancy services by rendering the top talent with innovative staffing and technology solutions. Headquartered in Fort Lee, NJ with offices across the United States, Europe, and India we offer a wide range of services to startups, mid-size companies and more. Ensuring capability, reliability and reducing redundancy when working with accomplished and capable individuals is the building block of a company, and we are here to help do just that.
              </p>
              <p className="py-2 para-light">
              Our flexible staffing options include contract, contract-to-hire, and direct hire. Our seasoned team of recruiters prioritize bringing you the top talent through evaluating a large volume of candidates to make it easier for you to choose the one that fits your company culture the best. XPR Soft is also an expert in providing you solutions in technological roadblocks. Ensuring our clients perform at the peak of their productivity with a top-notch IT infrastructure is the main reason our key areas extend to Digital Transformation, Quality Assurance and Salesforce/CRM solutions. 
              </p>
              <p className="py-2 para-light">
              Regardless of what you need in your next career move for yourself, or for your business, XPR Soft is designated to be your partner of choice for life. Don’t hold yourself back on elevating your skills, your business infrastructure, and your IT transformation. Reach out to us, so we can help combining our dual focus with your passion to be prominent.
              </p>
            </div>
            <div className="col-lg-5 text-center py-4 py-sm-0" data-aos="fade-down">
              <img className="img-fluid" src="images/about.jpg" alt="about" />
            </div>
          </div>
        </div>
      </section>

      <section className="services d-flex align-items-center py-5" id="services">
        <div className="container text-light">
          <div className="text-center pb-4">
            <p>OUR SERVICES</p>
            <h2 className="py-2">Explore unlimited possibilities</h2>
            <p className="para-light">
              We provide wide range of services. Our mission is to provide best
              solution that helps everyone.
            </p>
          </div>
          <div className="row gy-4 py-2" data-aos="zoom-in">
            <div className="col-lg-4">
              <div className="card bg-transparent">
                <i className="fas fa-briefcase fa-2x"></i>
                <h4 className="py-2">SALESFORCE</h4>
                <p className="para-light">
                We take the pulse of customers from marketing to sales, after
                sales services to communication networks and turn your customers
                into brand ambassadors with solutions that exceed their
                expectations. By personalizing your customer relationship
                experiences with Customer Relationship Management (CRM)
                solutions, you will turn market dynamics into opportunities with
                a strong management network.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card bg-transparent">
                <i className="fas fa-graduation-cap fa-2x"></i>
                <h4 className="py-2">EDUCATION</h4>
                <p className="para-light">
                The dependence of the rapidly developing and changing education
                sector to technology and the need to facilitate the steps in its
                processes are increasing day by day. We offer an integrated
                solution to simplify and support business processes that create
                a lot of workload in terms of human resources. Our Solutions
                enables you to manage all your financial and administrative
                processes systematically.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card bg-transparent">
                <i className="fas fa-user-plus fa-2x"></i>
                <h4 className="py-2">STAFFING</h4>
                <p className="para-light">
                We deliver top talent to our clients by combining the use of the
                latest technology with the subject-matter expertise that our
                seasoned team of recruiters bring to the process. <br></br>
                With the integration of smart sourcing tools, machine learning and
                AI/Artificial Intelligence into our screening process across the
                organization; we evaluate a large volume of candidates to find
                the most suitable talent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    <section className="work d-flex align-items-center py-5">
      <div className="container-fluid text-light">
        <div className="row">
          <div
            className="col-lg-6 d-flex justify-content-center align-items-center"
            data-aos="fade-right"
          >
            <img className="img-fluid" src="images/work.jpg" alt="work" />
          </div>
          <div className="col-lg-5 d-flex align-items-center px-4 py-3" data-aos="">
            <div className="row">
              <div className="text-center text-lg-start py-4 pt-lg-0">
                <p>OUR WORK</p>
                <p className="para-light">
                  We Create Digital Transformation with Customer Focused Cloud
                  Communications Solutions.
                </p>
              </div>
              <div className="container" data-aos="fade-up">
                <div className="row g-5">
                  <div className="col-4 text-start">
                    <i className="fas fa-briefcase fa-2x text-start"></i>
                    <h2
                      className="purecounter"
                      data-purecounter-start="0"
                      data-purecounter-end="258"
                      data-purecounter-duration="3"
                    >
                      258
                    </h2>
                    <p>PROJECTS COMPLETED</p>
                  </div>
                  <div className="col-4">
                    <i className="fas fa-house-user fa-2x"></i>
                    <h2
                      className="purecounter"
                      data-purecounter-start="0"
                      data-purecounter-end="155"
                      data-purecounter-duration="3"
                    >
                      155
                    </h2>
                    <p>CUSTOMER ACTIVE</p>
                  </div>
                  <div className="col-4">
                    <i className="fas fa-clock fa-2x"></i>
                    <h2
                      className="purecounter"
                      data-purecounter-start="0"
                      data-purecounter-end="57"
                      data-purecounter-duration="3"
                    >
                      57
                    </h2>
                    <p>GOOD REVIEWS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



      <section className="contact d-flex align-items-center py-5" id="contact">
        <div className="container-fluid text-light">
          <div className="row">
            <div
              className="col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center px-lg-5"
              data-aos="fade-right"
            >
              <div style={{ width: "90%" }}>
                <div className="text-center text-lg-start py-4 pt-lg-0">
                  <p>CONTACT</p>
                  <h2 className="py-2">Send your query</h2>
                  <p className="para-light">
                    You can send us a message by entering your contact
                    information. You will be returned as soon as possible.
                  </p>
                </div>
                <form className="contact-form" onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group py-2">
                        <input
                          type="text"
                          className="form-control form-control-input"
                          id="contact-name"
                          name="name"
                          value={contactForm.name}
                          placeholder="Enter name"
                          onChange={handleFormInput}
                          pattern="^[A-Za-z]+),\\s+([A-Za-z]+)\\s+([A-Za-z]+$"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group py-2">
                        <input
                          type="tel"
                          className="form-control form-control-input"
                          id="contact-phone"
                          name="phone"
                          value={contactForm.phone}
                          placeholder="Enter phone number"
                          onChange={handleFormInput}
                          required
                          patern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                        />
                        <small>Format: 123 456 7890</small>
                      </div>
                    </div>
                  </div>
                  <div className="form-group py-1">
                    <input
                      type="email"
                      className="form-control form-control-input"
                      id="contact-email"
                      name="email"
                      value={contactForm.email}
                      placeholder="Enter email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      onChange={handleFormInput}
                      required
                    />
                  </div>
                  <div className="form-group py-2">
                    <textarea
                      className="form-control form-control-input"
                      id="contact-query"
                      name="query"
                      value={contactForm.query}
                      rows="6"
                      placeholder="Write your message here..."
                      onChange={handleFormInput}
                      required
                    ></textarea>
                  </div>
                  <div className="my-3 d-flex justify-content-between">
                  <input
                  value="Submit Form"
                    type="submit"
                    className="btn"
                  />                    
                  {
                message && <Alert className="p-2 text-center w-50" variant="success" >{message}</Alert>
              }
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="fade-down"
            >
              <img
                className="img-fluid d-none d-lg-block"
                src="images/contact.jpg"
                alt="contact"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="location text-light py-5">
        <div className="container" data-aos="zoom-in">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              
              <div className="p-2">
              <a href="https://goo.gl/maps/fjeZVTxp5XiepnJL6">
                <i className="far fa-map fa-3x"></i>
                </a>
              </div>
              <div className="ms-2">
                <h6>ADDRESS</h6>
                <a href="https://goo.gl/maps/fjeZVTxp5XiepnJL6">
                <p>800 Park Ave., Fort Lee, NJ 07024 USA</p>
                </a>
              </div>
            </div>
            
            <div className="col-lg-4 d-flex align-items-center">
              <div className="p-2">
              <a href="tel:+19087770784">
                <i className="fas fa-mobile-alt fa-3x"></i></a>
              </div>
              <div className="ms-2">
                <h6>CALL FOR QUERY</h6>
                <p><a href="tel:+19087770784">+1 (908) 777 0784</a></p>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center">
              <div className="p-2">
              <a href="mailto:info@xprsoft.com" target="mailto:info@xprsoft.com">
                <i className="far fa-envelope fa-3x"></i>
                </a>
              </div>
              <div className="ms-2">
                <h6>SEND US MESSAGE</h6>
                <p><a href="mailto:info@xprsoft.com" target="mailto:info@xprsoft.com">Send EMail!</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer text-light">
        <div className="container">
          <div className="row" data-aos="fade-right">
            <div className="col-lg-8 py-4 py-md-5">
              <div className="d-flex align-items-center">
                <h4 className="">XPRSoft</h4>
              </div>
              <p className="py-3 para-light">
                We Create Digital Transformation with Customer Focused Cloud
                Communications Solutions
              </p>
              {/* <div className="d-flex">
                <div className="me-3">
                  <a href="https://www.facebook.com/xprsoft">
                    <i className="fab fa-facebook-f fa-2x py-2"></i>
                  </a>
                </div>
                <div className="me-3">
                  <a href="https://www.twitter.com/xprsoft">
                    <i className="fab fa-twitter fa-2x py-2"></i>
                  </a>
                </div>
                <div className="me-3">
                  <a href="https://www.instagram.com/xprsoft">
                    <i className="fab fa-instagram fa-2x py-2"></i>
                  </a>
                </div>
              </div> */}
            </div>

            <div className="col-lg-4 py-4 py-md-5">
                <h4 className="py-2">Quick Links</h4>
              <div className="row" >
              <div className="col-lg-6">

                <div className="d-flex align-items-center py-2">
                  <i className="fas fa-caret-right"></i>
                  <a href="#about">
                    <p className="ms-3">About</p>
                  </a>
                </div>
                <div className="d-flex align-items-center py-2">
                  <i className="fas fa-caret-right"></i>
                  <a href="#services">
                    <p className="ms-3">Services</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center py-2">
                  <i className="fas fa-caret-right"></i>
                  <a href="#contact">
                    <p className="ms-3">Contact</p>
                  </a>
                </div>
                <div className="d-flex align-items-center py-2">
                  <i className="fas fa-caret-right"></i>
                  <a href="/login">
                    <p className="ms-3">Login</p>
                  </a>
                </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </section>

      <button onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} id="myBtn">
        <img src="images/up-arrow.png" alt="alternative" />
      </button>

      {/* <div>
        <a
          className="bi-whatsapp"
          style={{ fontSize: "2rem" }}
          href="https://wa.me/+19087770784?text=Hello XPRSoft! I want to get information."
          id="whatsapp-sticky"
        >
          <i className="fab fa-whatsapp fa-stack-1x"></i>
        </a>
      </div> */}
    </div>
  );
};

export default HomePage;
