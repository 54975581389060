import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { API_url } from "../config/config";
import "../Switch.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
// Bootstrap Components
import {
  InputGroup,
  FormControl,
  Badge,
  Table,
  Modal,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import {
  getAllUsers,
  getAllRoles,
  changeUserRole,
  changeUserStatus,
  changeUserVisible,
  sendUsertoHR
} from "../actions/admin.action";

import Pagination from "../components/Pagination";


const jobStatus = (contracts) => {
  if (contracts.length === 0) {
    return "Open to Work";
  } else {
    let sorted = contracts.sort(
      (a, b) => new Date(b.hireDate) - new Date(a.hireDate)
    );
    //console.log(sorted);
    switch (sorted[0].endDate === "" || sorted[0].endDate === null) {
      case false:
        return "Open to Work";
      case true:
        return "On the Job";
      default:
        return "Open to Work";
    }
  }
};

const initialState = {
  imgUrl: "",
  email: "",
  password: "",
  firstname: "",
  middlename: "",
  lastname: "",
  phone1: "",
  phone2: "",
  birthDate: "",
  placeOfBirth: "",
  nativeLanguage: "",
  gender: "",
  countryOrigin: "",
  race: "",
  code: "",
  roles: [],
  socials: [],
  userContracts: [],
  userDocuments: [],
  address: {
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
};

const AllUser = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [selected, setselected] = useState(initialState);
  const [selectedRole, setselectedRole] = useState([]);
  const [rolesAll, setrolesAll] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [status, setstatus] = useState("true");
  const [message, setmessage] = useState("");
  const [sendMessage, setsendMessage] = useState("");
  const [HRmail, setHRmail] = useState("")
  //const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { users } = useSelector((store) => store.admins);
  const { roles } = useSelector((store) => store.admins);
  const { user:current } = useSelector((store) => store.auth);

  const codeAreaRef = React.createRef();
  const handleClose = () => setShow(false);
  const handleCloseX = () => setmessage("");

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllUsers(currentPage, searchValue, status)).then((meta) =>
      settotalItems(meta.total)
    );
  }, [dispatch, currentPage, searchValue, status]);

  useEffect(() => {
    if (users) {
      setstate(users);
    }
  }, [users]);

  useEffect(() => {
    if (roles) {
      setrolesAll(roles);
    }
  }, [roles]);

  const onChangePage = (pageNum) => {
    setcurrentPage(pageNum);
  };

  const onFilterPageClear = (e) => {
    setsearchValue("");
  };

  function onRowClick(id) {
    const selected = users && users.find((item) => item._id === id);
    setselected(selected);
    setselectedRole(selected.roles);
    setShow(true);
  }

  const handleSearch = (e) => {
    setsearchValue(e.target.value);
    setcurrentPage(1);
  };

  const handleInput = (e) => {
    //console.log(e);
    let temp_selectedRole = [...selectedRole];

    if (e.target.checked) {
      temp_selectedRole.push(e.target.id);
      setselectedRole(temp_selectedRole);
    } else {
      let filteredRole = temp_selectedRole.filter(
        (item) => item !== e.target.id
      );
      setselectedRole(filteredRole);
    }
  };

  const handleChangeStatus = (id) => {
    setisLoading(true);
    dispatch(changeUserStatus(id)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setmessage("");
          setisLoading(false);
        }, 2000);
        dispatch(getAllUsers(currentPage, searchValue, status)).then((meta) =>
          settotalItems(meta.total)
        );
      }
    });
  };

  const handleSendHR = (id) => {
    dispatch(sendUsertoHR(id, HRmail)).then((res) => {
      if (res.status === 200) {
        setsendMessage(res.message);
        setHRmail("");
        setTimeout(() => {
          setsendMessage("");
        }, 2000);
      }
    });
  };

  const handleChangeVisible = (id) => {
    setisLoading(true);
    dispatch(changeUserVisible(id)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setmessage("");
          setisLoading(false);
        }, 2000);
        dispatch(getAllUsers(currentPage, searchValue, status)).then((meta) =>
          settotalItems(meta.total)
        );
      }
    });
  };

  const handleChangeRole = () => {
    setisLoading(true);
    dispatch(changeUserRole(selected._id, selectedRole)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setmessage("");
          setisLoading(false);
        }, 2000);
        dispatch(getAllUsers(currentPage, searchValue, status)).then((meta) =>
          settotalItems(meta.total)
        );
      }
    });
  };

  function copyToClipboard(e) {
    codeAreaRef.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 2000);
  }

  //console.log(current);
  //console.log(status);
  //console.log(selected);
  //console.log(selectedRole);

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Col lg={6}>
        <InputGroup className="mb-2">
          <InputGroup.Text>
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
          <FormControl
            id="userAllSearch"
            type="text"
            placeholder="Search users..."
            name="searchValue"
            value={searchValue}
            onChange={handleSearch}
            size="sm"
          />
          <InputGroup.Text
            onClick={onFilterPageClear}
            style={{ cursor: "pointer" }}
            title="Clear"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ fontWeight: "bolder" }}>
            <th>Name</th>
            {!isMobile && (
              <>
                <th>Contract</th>
                <th>HireDate</th>
                <th>EndDate</th>
              </>
            )}
            <th>Job Status</th>
            <th>Show HR</th>
            <th>
              <div>
                <Form.Check
                  inline
                  label="Active"
                  name="Status"
                  type="radio"
                  id="radio-1"
                  defaultChecked
                  onClick={(e) => {setstatus("true"); setcurrentPage(1)}}
                />
                <br></br>
                <Form.Check
                  inline
                  label="Passive"
                  name="Status"
                  type="radio"
                  id="radio-2"
                  onClick={(e) => {setstatus("false"); setcurrentPage(1)}}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {state ? (
            state.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td
                    className="text-muted"
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => onRowClick(item._id)}
                  >
                    {item.firstname.charAt(0).toUpperCase() +
                      item.firstname.slice(1) +
                      " " +
                      item.lastname.charAt(0).toUpperCase() +
                      item.lastname.slice(1)}
                  </td>
                  {!isMobile && (
                    <>
                      <td>
                        {item.userContracts.map((elem, i) => (
                          <div key={i}>{elem.company}</div>
                        ))}
                      </td>
                      <td>
                        {item.userContracts.map((elem, i) => (
                          <div key={i}>
                            {new Date(elem.hireDate).toLocaleDateString()}
                          </div>
                        ))}
                      </td>
                      <td>
                        {item.userContracts.map((elem, i) => (
                          <div key={i}>
                            {elem.endDate ? (
                              new Date(elem.endDate).toLocaleDateString()
                            ) : (
                              <Alert variant="info p-0">Continue</Alert>
                            )}
                          </div>
                        ))}
                      </td>
                    </>
                  )}
                  <td className="p-0 text-center">
                    <Badge
                      bg={
                        jobStatus(item.userContracts) === "Open to Work"
                          ? "danger "
                          : "info "
                      }
                    >
                      {jobStatus(item.userContracts)}
                    </Badge>
                  </td>
                  <td className="p-0 text-center showHr">
                    <Form.Check
                      inline
                      // label={item.showOperator? "Yes" : "No"}
                      name="showOperator"
                      type="switch"
                      title="Click to change HR visibility"
                      defaultChecked={item.showOperator}
                      onClick={() => handleChangeVisible(item._id)}
                      disabled={!item.status}
                    />
                  </td>
                  <td className="p-0 text-center showStatus">
                    <Form.Check
                      inline
                      // label={item.status? "Active" : "Passive"}
                      name="status"
                      type="switch"
                      title="Click to change user status"
                      defaultChecked={item.status}
                      onClick={() => handleChangeStatus(item._id)}
                      disabled={(current && current.id === item._id) ? true : false}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {selected ? (
        <Modal
          size={isMobile ? "sm" : "lg"}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <div className="d-flex align-items-end">
              <img
                style={{ height: 52, width: 52 }}
                src={`${API_url}/api/file/${selected.imgUrl}`}
                className="rounded-circle"
                alt="Profile"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={3}>
                <span>Email</span>
              </Col>
              <Col sm={9}>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-at"></i> {selected.email}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Name</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-signature"></i>{" "}
                    {selected.firstname.charAt(0).toUpperCase() +
                      selected.firstname.slice(1)}{" "}
                    {selected.middlename.charAt(0).toUpperCase() +
                      selected.middlename.slice(1)}{" "}
                    {selected.lastname.charAt(0).toUpperCase() +
                      selected.lastname.slice(1)}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Contact</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-phone"></i>
                    {"  "} Phone-1: {selected.phone1}
                  </span>
                  <span>
                    <i className="fas fa-phone"></i>
                    {"  "} Phone-2: {selected.phone2}
                  </span>
                  {selected &&
                    selected.socials &&
                    selected.socials.map((social, i) => {
                      return (
                        <span key={i}>
                          <i
                            className={`fab fa-${
                              social.name === "stackoverflow"
                                ? "stack-overflow"
                                : social.name
                            }`}
                          ></i>{" "}
                          {"  "}
                          {social.url}
                        </span>
                      );
                    })}
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Address</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  {selected.address && (
                    <span>
                      <i className="fas fa-map-marked-alt"></i>
                      {"  "} {selected.address.address_line_1}{" "}
                      {selected.address.address_line_2} {selected.address.city}{" "}
                      {selected.zip_code} {selected.address.state}
                    </span>
                  )}
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Contracts</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  {selected &&
                    selected.userContracts &&
                    selected.userContracts.map((contract, i) => {
                      return (
                        <span key={i}>
                          <span>
                            <i className="fas fa-file-signature"></i>
                            {"  "}
                            Name : {contract.contractname}
                          </span>{" "}
                          {" / "}
                          <span>{contract.company}</span>
                        </span>
                      );
                    })}
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Documents</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  {selected &&
                    selected.userDocuments &&
                    selected.userDocuments.map((document, i) => {
                      return (
                        <span key={i}>
                          <span>
                            <i className="fas fa-file-alt"></i> Name:{" "}
                            {document.name}
                          </span>{" "}
                          {" / "}
                          <span>
                            {" "}
                            Date:{" "}
                            {document.updatedAt
                              ? new Date(
                                  document.updatedAt
                                ).toLocaleDateString()
                              : ""}{" "}
                          </span>
                        </span>
                      );
                    })}
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>User Code</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span className="d-flex align-items-baseline">
                    <i className="fas fa-key"></i>
                    {"  "}
                    <textarea
                      ref={codeAreaRef}
                      value={selected.code}
                      cols="38"
                      rows="1"
                      style={{
                        outline: "none",
                        border: "none",
                        resize: "none",
                      }}
                      readOnly
                    />{" "}
                    <i
                      className="fas fa-copy"
                      onClick={copyToClipboard}
                      title="Copy to clipboard!"
                      style={{ cursor: "pointer" }}
                    ></i>
                    {copySuccess}
                  </span>
                </ListGroup>
                <ListGroup variant="flush">
                  <Form.Label className="col-lg-6" column="sm">Send user mail and code to a HR</Form.Label>
                  {sendMessage && <Form.Label className="col-lg-6" column="sm" style={{color:"green"}}><i className="fas fa-check-circle"></i>{sendMessage}</Form.Label>}
                <InputGroup className="mb-2 col-lg-6" >
                  <FormControl
                    id="sendCode"
                    type="text"
                    placeholder="Write HR mail address here..."
                    name="HRmail"
                    value={HRmail}
                    onChange={(e)=>setHRmail(e.target.value)}
                    size="sm"
                  />
                  <InputGroup.Text size="sm"
                    onClick={()=>handleSendHR(selected._id)}
                    style={{ cursor: "pointer" }}
                    title="Clear"
                    >
                    Send
                  </InputGroup.Text>
                </InputGroup>
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>Additional</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <ListGroup>
                  <span>
                    <i className="fas fa-birthday-cake"></i>
                    {"  "} Birth Date :
                    {selected.birthDate
                      ? new Date(selected.birthDate).toLocaleDateString()
                      : ""}
                  </span>
                  <span>
                    <i className="fab fa-sourcetree"></i>
                    {"  "} Origin: {selected.countryOrigin}
                  </span>
                  <span>
                    <i className="fas fa-globe-americas"></i>
                    {"  "} Place Of Birth: {selected.placeOfBirth}
                  </span>
                  <span>
                    <i className="far fa-surprise"></i>
                    {"  "} Race: {selected.race}
                  </span>
                  <span>
                    <i className="fas fa-venus-mars"></i>
                    {"  "} Gender: {selected.gender}
                  </span>
                  <span>
                    <i className="fas fa-language"></i>
                    {"  "} Native Lang: {selected.nativeLanguage}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={3}>
                <hr className="mt-0"></hr>
                <span>User Role</span>
              </Col>
              <Col sm={9}>
                <hr className="mt-0"></hr>
                <div className="d-flex align-items-center">
                  {rolesAll.map((role) => {
                    return (
                      <Form.Check
                        className={"d-flex align-items-center px-4"}
                        key={role._id}
                        size="lg"
                        type="checkbox"
                        id={role._id}
                        name={role.name}
                        label={role.name.toUpperCase() + "  "}
                        defaultChecked={
                          selected &&
                          selected.roles &&
                          selected.roles.includes(role._id)
                        }
                        // disabled={role.name === "user" ? true : false}
                        onChange={handleInput}
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div>
              <Button
                variant="danger"
                className="p-1 btn1"
                size={isMobile && "sm"}
                onClick={handleChangeRole}
                disabled={message}
              >
                Update Role
              </Button>
            </div>
            <div>
              <Button
                //variant="secondary"
                className="p-1 btn1"
                size={isMobile && "sm"}
                onClick={handleClose}
              >
                Close
              </Button>
              {/* <Button
                variant="primary"
                className="p-1 btn2"
                size={isMobile && "sm"}
                // onClick={handleSubmit}
                disabled={message}
              >
                Update
              </Button> */}
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      <Modal show={message ? true : false} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success mt-2" role="alert">
            {message}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllUser;
