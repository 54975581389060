import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import AllUser from "../components/AllUser";
import AllContract from "../components/AllContract";
import AllDocument from "../components/AllDocument";


const BoardAdmin = (props) => {

  return (
    <div
    className="card shadow p-3 mt-3 boarduser"
    style={{
      maxWidth: "1024px",
      margin: "0 auto",
    }}
  >
    <div>
      <Tabs defaultActiveKey="alluser" id="admin-panel" className=" mb-3">
        <Tab eventKey="alluser" title="All Users">
        <div key='uniqueKey1'>
          <AllUser {...props}/>
          </div>
        </Tab>
         <Tab eventKey="allcontract" title="All Contracts">
         <div key='uniqueKey2'>
          <AllContract {...props}/>
          </div>
        </Tab>
        <Tab eventKey="alldocument" title="All Files">
        <div key='uniqueKey3'>
          <AllDocument  {...props}/>
          </div>
        </Tab>
        
      </Tabs>
    </div>
  </div>
  );
};

export default BoardAdmin;
