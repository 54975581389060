import React, { useState, useEffect } from "react";
import { API_url } from "../config/config";
// Redux
import { useDispatch } from "react-redux";
import {
  Button,
  ListGroup,
  Card,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

//UI Components
import { showUsertoHR } from "../actions/supervisor.action";
import { downloadHRDocument } from "../actions/document.action";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

const BoardOperator = () => {
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const [state, setstate] = useState({});
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const cvReference = React.createRef();
  const [downloadURL, setdownloadURL] = useState("");

  useEffect(() => {
    if (state && state.userDocuments && state.userDocuments.length > 0) {
      dispatch(downloadHRDocument(state.userDocuments[0].url)).then((res) => {
        const objectURL = window.URL.createObjectURL(res);
        setdownloadURL(objectURL);
      });
    }
  }, [dispatch, state]);

  const handleData = (e) => {
    e.preventDefault();
    const lengthCode = code.length;
    //console.log(lengthCode);
    if (!email || !code) {
      setMessage("Required Fields,Plese fill out...");
    } else if (lengthCode !== 30) {
      setMessage("Incorrect entry");
    } else {
      dispatch(showUsertoHR(email, code)).then((res) => {
       // console.log(res);
        if (res.status === 200) {
          setstate(res && res.user && res.user[0]);
          setIsVisible(true);
          setMessage("");
        } else if (res.status === 400) {
          setMessage(res.data.msg);
          setTimeout(() => {
            setMessage("");
          }, 3000);
        }
      });
    }
  };

  const handleClear = () => {
    setIsVisible(false);
    setEmail("")
    setCode("")
    setstate({})
  }

  //console.log(email, code, isVisible);
  return (
    <div className="container">
      <div className="container mt-5">
        <h2 className="text-center para-light" style={{ color: "white" }}>
          Human Resource
        </h2>
        <h3 className="text-center para-light" style={{ color: "white" }}>
          Search Screen
        </h3>
      </div>
      <div className="jumbotron mt-1" style={{ margin: "0 auto" }}>
          {!isVisible ? (
            <div className="col-md-12">
              <div
                className="card card-container rounded mt-1"
                style={{ backgroundColor: "#212d37" }}
              >
                <Form onSubmit={handleData}>
                  <div
                    className="form-group fw-bolder mb-2"
                    style={{ color: "#ef7d1a" }}
                  >
                    <label htmlFor="email" className="mb-1">
                      Email:{"  "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </label>
                    <Input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Enter Candidate Email."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group fw-bolder">
                    <label
                      htmlFor="code"
                      //className="mb-1"
                      style={{ color: "#ef7d1a" }}
                    >
                      HR Code:{"  "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required, Please enter the candidate code
                            correctly.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </label>
                    <Input
                      className="form-control mb-4"
                      name="password"
                      type="password"
                      placeholder="Enter HR Code."
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  {message && (
                    <div className="text-center">
                      <Alert variant="danger">{message}</Alert>
                    </div>
                  )}
                  <Button
                    size="sm"
                    type="submit"
                    variant="warning mt-3 mx-auto d-flex"
                    className="mt-1 mx-auto d-flex mb-1"
                  >
                    Send Data
                  </Button>
                </Form>
              </div>
            </div>
          ) : (
              <Card id="modCard">
                 <div className="d-flex justify-content-between align-items-center mb-2">
                <Card.Img
                  variant="top"
                  className="mx-auto rounded-circle"
                  style={{ height: 100, width: 100 }}
                  //src="https://www.w3schools.com/howto/img_avatar.png"
                  src={`${API_url}/api/file/${state.imgUrl}`}
                />
                <Button
                size="sm"
                variant="fw-bolder fs-7 me-2 py-2 px-3"
                onClick={handleClear}
                className={"btn1"}
              >
                New Seacrh
              </Button>
              </div>
                <Card.Title className="mt-2 mx-auto">
                  {`${
                    state &&
                    state.firstname.charAt(0).toUpperCase() +
                      state.firstname.slice(1)
                  } 
                  ${
                    state &&
                    state.middlename.charAt(0).toUpperCase() +
                      state.middlename.slice(1)
                  }
                  ${
                    state &&
                    state.lastname.charAt(0).toUpperCase() +
                      state.lastname.slice(1)
                  }`}
                </Card.Title>
                {state &&
                  state.userContracts.map((item, index) => (
                    <Card.Body key={index}>
                      <Row>
                        <Col sm={4}>
                          <Card.Title style={{ fontSize: "18px" }}>
                            <u> {item.company}</u>
                          </Card.Title>
                        </Col>
                        <Col sm={8}>
                          <ListGroup className="list-group-flush">
                            <ListGroupItem>
                              <span>Position: </span>
                              {item.position}
                            </ListGroupItem>
                            <ListGroupItem>
                              <span>Start Date: </span>
                              {item.hireDate &&
                                new Date(item.hireDate).toLocaleDateString()}
                            </ListGroupItem>
                            <ListGroupItem>
                              <span>End Date:</span>
                              {item.endDate
                                ? new Date(item.endDate).toLocaleDateString()
                                : " In progress..."}
                            </ListGroupItem>
                          </ListGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  ))}
                {state &&
                  state.userDocuments &&
                  state.userDocuments.length > 0 && (
                    <Card.Footer>
                      <a
                        ref={cvReference}
                        href={downloadURL}
                        download={
                          state.firstname + "_" + state.lastname + "_resume"
                        }
                      >
                        <Button
                          size="sm"
                          type="button"
                          variant=" btn2 success  mx-auto d-flex"
                          className="mx-auto d-flex"
                        >
                          Download CV
                        </Button>
                      </a>
                    </Card.Footer>
                  )}
              </Card>
          )}
      </div>
    </div>
  );
};

export default BoardOperator;
