import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";
import Messages from "../components/Messages";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_url } from "../config/config";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const initialState = {
  email: "",
  firstname: "",
  middlename: "",
  lastname: "",
  phone: "",
};

const ResetPassword = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1c262f";
  });
  const initialNewPass = {newpass1: "", newpass2: "" };

  const form = useRef();
  const checkBtn = useRef();



  const [successful, setSuccessful] = useState(false);
   const [message, setMessage] = useState(null);
  const [newpassState, setnewpassState] = useState(initialNewPass);
  //const { message } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);

    
  //PUT METHOD//
  //GET TOKEN//
  const currentHref = window.location.href;
  //console.log("currentHref =", currentHref);
  const tokenStartIndex = currentHref.indexOf("=") + 1;
  //alert(tokenStartIndex);
  const tokenEndIndex = currentHref.indexOf("&");
  // alert(tokenEndIndex);
  const token = currentHref.slice(tokenStartIndex, tokenEndIndex);
  //alert(token);

  //GET ID//
  const idStartIndex = currentHref.lastIndexOf("=") + 1;
  //alert(idStartIndex);
  const user_id = currentHref.slice(idStartIndex);
  //alert(userId);

  const handleInputPass = (e) => {
    setnewpassState({ ...newpassState, [e.target.name]: e.target.value });
  };

  const handlePassword = (e) => {

    setSuccessful(false);
    e.preventDefault();
    if (newpassState.newpass1 !== newpassState.newpass2) {
      
      setMessage("Please check your new password");
      return;
    }
    if (newpassState.newpass1 === "" && newpassState.newpass2 === "") {
      setMessage(
        "You can not set your new password empty! Please check your new password"
      );
      return;
    }
    if (
      newpassState.newpass1 === newpassState.newpass2 &&
      newpassState.newpass1.length < 6
    ) {
      setMessage(
        "New password should be at least 6 character! Please check your new password"
      );
      return;
    }
    const newPassword=newpassState.newpass1;
    
    axios.put(`${API_url}/api/password-reset`,{ user_id,token, newPassword})
    .then((res) => {
     // console.log(res);
      if (res.data.status === 200) {
        setIsVisible(false)
        setSuccessful(true);
        setMessage(res.data.message)
      }
    }).catch((err)=>{
    setSuccessful(false);
     // console.log(err.response.data.message)
      setMessage(err.response ? err.response.data.message : err.message)
    })
  };

  return (
    <div className="col-md-12">
      <div
        className="card card-container mt-5"
        style={{ backgroundColor: "#212d37" }}
      >
        <img src="/images/logo_banner.png" alt="" className="mb-4 mt-4" />
        {isVisible ? (
            <Form
            //onSubmit={handleRegister}
            ref={form}
          >
            {!successful && (
              <div>
                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="fw-bolder mb-1"
                    style={{ color: "#ef7d1a" }}
                  >
                    Create Your New Password
                  </label>
                  <Input
                    type="password"
                    className="form-control mb-4"
                    value={newpassState.newpass1}
                    size="sm"
                    name="newpass1"
                    onChange={handleInputPass}
                  />
                </div>
  
                <div className="form-group">
                  <label
                    htmlFor="confirmpassword"
                    className="fw-bolder mb-1"
                    style={{ color: "#ef7d1a" }}
                  >
                    Confirm Password
                  </label>
                  <Input
                    type="password"
                    className="form-control mb-4"
                    value={newpassState.newpass2}
                    size="sm"
                    name="newpass2"
                    onChange={handleInputPass}
                  />
                </div>
                <div className="form-group d-grid gap-2 my-3">
                  <button 
                  className="btn btn-primary"
                  onClick={handlePassword}
                  >Submit</button>
                </div>
              </div>
            )}
  
            {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
                
              </div>
            )}
  
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>

        ) : (
          message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
              <Link className="mt-3 mb-1 float-end fw-bold" to="/">
            Return Home
          </Link>
            </div>
          )
        )}
        
      </div>
    </div>
  );
};

export default ResetPassword;
