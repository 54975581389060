import axios from "axios";
import { API_url } from '../config/config'
import { logout } from "./auth";


import {
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_GET_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  DOCUMENT_DELETE_FAIL,
  DOCUMENT_ADD_FAIL
} from "./types";

export const addNewDocument = (state) => async (dispatch, getState) => {
  //console.log("add new document calıstı")
  try {
    const {auth: { user },} = getState();
    //Header to send with the request
    const config = {
      headers: {
        'x-access-token': `${user.accessToken}`,
      },
    };
    const { data } = await axios.post(`${API_url}/api/document`, state, config);
    //console.log(data)
    return data;
  } catch (error) {
    if(error.response && error.response.status === 401){
        dispatch(logout());
      }
    return error.response ? error.response.data : error;
  }
};

  export const getUserDocument = () => async (dispatch, getState) => {
  //  console.log("get oneuser document calıstı")
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.get(`${API_url}/api/document?sort=-createdAt`, config);
      //console.log(data)
      dispatch({
        type: DOCUMENT_GET_SUCCESS,
        payload: data.documents,
      });
      return data.status;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: DOCUMENT_GET_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };

  export const updateUserDocument = (state, id) => async (dispatch, getState) => {
  //  console.log("update user document calıstı", state, id)
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.put(`${API_url}/api/document/${id}`, state, config);
      //console.log(data)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error.response ? error.response.data : error;
    }
  };
  
  export const deleteUserDocument = (id) => async (dispatch, getState) => {
  //  console.log("delete user document calıstı")
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.delete(`${API_url}/api/document/${id}`, config);
   //  console.log(data)
     return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error.response ? error.response.data : error;
    }
  };

  
  
  export const downloadDocument = (name) => async (dispatch, getState) => {
    //console.log("get document from action calıstı", name)
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        }
      };      
      const data = fetch(`${API_url}/api/filedownload/${name}`, config)
      .then(response =>  response.blob())
      .then(resData => {
         return resData;
      })  
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };
  
  export const downloadHRDocument = (name) => async (dispatch, getState) => {
    //console.log("get document from action calıstı", name)
    try {  
      const data = fetch(`${API_url}/api/hrdownload/${name}`)
      .then(response =>  response.blob())
      .then(resData => {
         return resData;
      })  
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };
