import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  Button,
  DropdownButton,
  Dropdown,
  Alert,
  Modal,
} from "react-bootstrap";
import dateFormat from "dateformat";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneUser,
  updateUser,
  updateUserPassword,
} from "../actions/user.action";
import { API_url } from "../config/config";
const initialState = {
  imgUrl: "",
  email: "",
  password: "",
  firstname: "",
  middlename: "",
  lastname: "",
  phone1: "",
  phone2: "",
  birthDate: "",
  placeOfBirth: "",
  nativeLanguage: "",
  gender: "",
  countryOrigin: "",
  race: "",
  address: {
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
};

const initialSocials = [
  { name: "", url: "" },
  { name: "", url: "" },
  { name: "", url: "" },
];

const initialNewPass = { currentpassword: "", newpass1: "", newpass2: "" };

const socialMedia = [
  "twitter",
  "facebook",
  "linkedin",
  "flickr",
  "tumblr",
  "xing",
  "github",
  "gitlab",
  "stackoverflow",
  "youtube",
  "dribbble",
  "instagram",
  "pinterest",
  "vk",
  "yahoo",
  "behance",
  "reddit",
  "vimeo",
];

export default function UserPersonal(props) {
  const dispatch = useDispatch();
  const [isEditing, setisEditing] = useState(false);
  const [imgFile, setimgFile] = useState({});
  //const user = props.staffData;
  const [state, setstate] = useState(initialState);
  const [uploadMessage, setuploadMessage] = useState("");
  const { current } = useSelector((store) => store.users);
  const { message } = useSelector((store) => store);
  const [show, setshow] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [socials, setsocials] = useState(initialSocials);
  const [newpassState, setnewpassState] = useState(initialNewPass);
  const [alert, setalert] = useState("");
  const inputReference = React.createRef();

  useEffect(() => {
    dispatch(getOneUser());
  }, [dispatch]);

  useEffect(() => {
    if (current) {
      setCurrenttoState();
    }
  }, [current]);

  function setCurrenttoState() {
    setstate({
      imgUrl: current.imgUrl !== "undefined" ? current.imgUrl : "",
      email: current.email !== "undefined" ? current.email : "",
      password: current.password !== "undefined" ? current.password : "",
      firstname: current.firstname !== "undefined" ? current.firstname : "",
      middlename: current.middlename !== "undefined" ? current.middlename : "",
      lastname: current.lastname !== "undefined" ? current.lastname : "",
      phone1: current.phone1 !== "undefined" ? current.phone1 : "",
      phone2: current.phone2 !== "undefined" ? current.phone2 : "",
      birthDate: current.birthDate !== "undefined" ? current.birthDate : "",
      placeOfBirth:
        current.placeOfBirth !== "undefined" ? current.placeOfBirth : "",
      nativeLanguage:
        current.nativeLanguage !== "undefined" ? current.nativeLanguage : "",
      gender: current.gender !== "undefined" ? current.gender : "",
      countryOrigin:
        current.countryOrigin !== "undefined" ? current.countryOrigin : "",
      race: current.race !== "undefined" ? current.race : "",
      address: {
        address_line_1:
          current.address.address_line_1 !== "undefined"
            ? current.address.address_line_1
            : "",
        address_line_2:
          current.address.address_line_2 !== "undefined"
            ? current.address.address_line_2
            : "",
        city: current.address.city !== "undefined" ? current.address.city : "",
        state:
          current.address.state !== "undefined" ? current.address.state : "",
        zip_code:
          current.address.zip_code !== "undefined"
            ? current.address.zip_code
            : "",
      },
    });
    setsocials(
      current.socials !== "undefined"
        ? JSON.parse(JSON.stringify(current.socials))
        : JSON.parse(JSON.stringify(initialSocials))
    );
  }

  const handleEditing = () => {
    setisEditing(!isEditing);
  };

  const handleClearEdit = () => {
    setisEditing(false);
    setCurrenttoState();
    setimgFile({});
    setuploadMessage("");
  };

  const imgUploadAction = () => inputReference.current.click();
  const imgUploadInputChange = (e) => {
    //console.log(e)
    if (
      e.target.files.length > 0 &&
      e.target.files[0].type.startsWith("image")
    ) {
      setimgFile(e.target.files[0]);
      setuploadMessage("Img selected!");
    }
  };

  const handleInput = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleInputPass = (e) => {
    setnewpassState({ ...newpassState, [e.target.name]: e.target.value });
  };
  const handleInputAddress = (e) => {
    setstate({
      ...state,
      address: { ...state.address, [e.target.name]: e.target.value },
    });
  };

  const handleSelectSocial = (e, i) => {
    // console.log(e.target.text);
    let tempSocial = [...socials];
    tempSocial[i].name = e.target.text;
    tempSocial[i].url = "https://www." + e.target.text + ".com/";
    setsocials([...tempSocial]);
  };
  const handleInputSocial = (e, i) => {
    let tempSocial = [...socials];
    tempSocial[i].url = e.target.value;
    setsocials([...tempSocial]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!state.firstname || !state.lastname || !state.email) {
      setalert("First name, last name or email can not be empty!");
      return;
    }

    const file = new FormData();
    if (imgFile.name) {
      file.append("file", imgFile);
    } else {
      file.append("imgUrl", state.imgUrl);
    }
    //console.log(file.get("file"));
    file.append("firstname", state.firstname);
    file.append("lastname", state.lastname);
    file.append("middlename", state.middlename);
    file.append("phone1", state.phone1);
    file.append("phone2", state.phone2);
    file.append("position", state.position);
    file.append("birthDate", state.birthDate);
    file.append("placeOfBirth", state.placeOfBirth);
    file.append("nativeLanguage", state.nativeLanguage);
    file.append("gender", state.gender);
    file.append("countryOrigin", state.countryOrigin);
    file.append("race", state.race);
    file.append("address", JSON.stringify(state.address));
    file.append("socials", JSON.stringify(socials));

    dispatch(updateUser(file)).then((status) => {
      // console.log(status);
      if (status === 200) {
        setuploadMessage("");
        setisEditing(false);
        setshow(true);
        setTimeout(() => {
          setshow(false);
        }, 2500);
      }
    });
  };

  const handlePassword = (e) => {
    e.preventDefault();
    if (newpassState.newpass1 !== newpassState.newpass2) {
      setalert("Please check your new password");
      return;
    }
    if (newpassState.newpass1 === "" && newpassState.newpass2 === "") {
      setalert(
        "You can not set your new password empty! Please check your new password"
      );
      return;
    }
    if (
      newpassState.newpass1 === newpassState.newpass2 &&
      newpassState.newpass1.length < 6
    ) {
      setalert(
        "New password should be at least 6 character! Please check your new password"
      );
      return;
    }
    dispatch(
      updateUserPassword({
        password: newpassState.currentpassword,
        newPassword: newpassState.newpass1,
      })
    ).then((data) => {
      //console.log(data);
      if (data.status === 200) {
        setshowPass(false);
        setnewpassState(initialNewPass);
        setshow(true);
        setTimeout(() => {
          setshow(false);
        }, 2500);
      } else if (data) {
        setalert(data.data.message);
        setTimeout(() => {
          setalert("");
        }, 2500);
      }
    });
  };

  const handleCancelPassUpdate = () => {
    setshowPass(false);
    setnewpassState(initialNewPass);
  };

  // console.log(imgFile);
  // console.log(state);
  // console.log(socials);
  // console.log(newpassState);

  return (
    <div className="pl-3 pr-3">
      <div className="row mt-3 pb-2 border-bottom">
        <div
          className="col-3 text-left text-muted d-none d-md-block"
          style={{ fontSize: 18, fontWeight: 500 }}
        >
          <span>Personal Information</span>
        </div>
        <div
          className="col-sm-12 col-md-9 text-left text-muted"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-end">
              <img
                style={{ height: 72, width: 72 }}
                src={
                  imgFile.name && typeof imgFile === "object"
                    ? URL.createObjectURL(imgFile)
                    : `${API_url}/api/file/${state.imgUrl}`
                }
                className="rounded-circle"
                alt="Profile"
              />
              <Form.Control
                type="file"
                hidden
                accept="image/*"
                ref={inputReference}
                onChange={imgUploadInputChange}
              />
              <span
                onClick={imgUploadAction}
                className={`position-relative ${!isEditing ? "d-none" : ""}`}
                style={{ right: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-camera fa-2x" aria-hidden="true"></i>
              </span>
              {uploadMessage && (
                <span className="ml-2" style={{ color: "green" }}>
                  {uploadMessage} <i className="fas fa-check"></i>
                </span>
              )}
            </div>
            {show && <Alert variant="success">{message}</Alert>}
            <div className="text-center">
              <div>
                <span
                  onClick={() => setshowPass(!showPass)}
                  style={{ cursor: "pointer" }}
                >
                  Change Password
                </span>
              </div>
              <div>
                <i
                  className="fas fa-user-lock fa-2x text-xpr"
                  aria-hidden="true"
                  onClick={() => setshowPass(!showPass)}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <div className="my-2 mx-4">
              <Button
                size="sm"
                variant="fw-bolder fs-7 me-2 py-2 px-3"
                className={`${!isEditing ? "d-none" : "btn1"}`}
                onClick={handleClearEdit}
              >
                Discard
              </Button>
              <Button
                size="sm"
                variant="fw-bolder fs-7 me-2 py-2 px-3"
                onClick={isEditing ? handleSubmit : handleEditing}
                className={"btn1"}
              >
                {isEditing ? "Save" : "Edit"}
              </Button>
            </div>

            <Modal
              show={showPass}
              onHide={handleCancelPassUpdate}
              color="danger"
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Form className="mb-2" onSubmit={handlePassword}>
                <Modal.Body>
                  <Form.Group>
                    <Form.Label column="sm">Current Password</Form.Label>
                    <Form.Control
                      required
                      value={newpassState.currentpassword}
                      size="sm"
                      type="password"
                      name="currentpassword"
                      onChange={handleInputPass}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column="sm">New Password</Form.Label>
                    <Form.Control
                      value={newpassState.newpass1}
                      size="sm"
                      type="password"
                      name="newpass1"
                      onChange={handleInputPass}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column="sm">Confirm New Password</Form.Label>
                    <Form.Control
                      value={newpassState.newpass2}
                      size="sm"
                      type="password"
                      name="newpass2"
                      onChange={handleInputPass}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    variant="fw-bolder fs-7 me-2 py-2 px-3"
                    className="btn1"
                  >
                    Submit{" "}
                  </Button>{" "}
                  <Button
                    variant="fw-bolder fs-7 me-2 py-2 px-3"
                    className="btn1"
                    onClick={handleCancelPassUpdate}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
              {alert ? <Alert variant="warning">{alert}</Alert> : ""}
            </Modal>
          </div>
          <Form size="sm">
            <Row className="mb-2">
              <Form.Group className="mb-2 col-lg-6" controlId="formGridEmail">
                <Form.Label column="sm">Email</Form.Label>
                <Form.Control
                  required
                  value={state.email}
                  size="sm"
                  type="email"
                  name="email"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="mb-2 col-lg-4"
                controlId="formGridFirstname"
              >
                <Form.Label column="sm">Firstname</Form.Label>
                <Form.Control
                  required
                  value={state.firstname}
                  size="sm"
                  name="firstname"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>

              <Form.Group
                className="mb-2 col-lg-4"
                controlId="formGridMiddlename"
              >
                <Form.Label column="sm">Middlename</Form.Label>
                <Form.Control
                  value={state.middlename}
                  size="sm"
                  name="middlename"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group
                className="mb-2 col-lg-4"
                controlId="formGridLastname"
              >
                <Form.Label column="sm">Lastname</Form.Label>
                <Form.Control
                  required
                  value={state.lastname}
                  size="sm"
                  name="lastname"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>
      <div className="row mt-3 pb-2 border-bottom">
        <div
          className="col-3 text-left text-muted d-none d-md-block"
          style={{ fontSize: 18, fontWeight: 500 }}
        >
          <span>Address Details</span>
        </div>
        <div
          className="col-sm-12 col-md-9 text-left text-muted"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          <Form size="sm">
            <Row className="mb-2">
              <Form.Group
                as={Col}
                className="mb-2"
                controlId="formGridAddress1"
              >
                <Form.Label column="sm">Address Line-1</Form.Label>
                <Form.Control
                  value={state && state.address && state.address.address_line_1}
                  size="sm"
                  name="address_line_1"
                  onChange={handleInputAddress}
                  disabled={!isEditing}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-2"
                controlId="formGridAddress2"
              >
                <Form.Label column="sm">Address Line-2</Form.Label>
                <Form.Control
                  value={state && state.address && state.address.address_line_2}
                  size="sm"
                  name="address_line_2"
                  onChange={handleInputAddress}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group className="col-lg-4" controlId="formGridCity">
                <Form.Label column="sm">City</Form.Label>
                <Form.Control
                  value={state && state.address && state.address.city}
                  size="sm"
                  name="city"
                  onChange={handleInputAddress}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="col-lg-4" controlId="formGridState">
                <Form.Label column="sm">State</Form.Label>
                <Form.Control
                  value={state && state.address && state.address.state}
                  size="sm"
                  name="state"
                  onChange={handleInputAddress}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="col-lg-4" controlId="formGridZip">
                <Form.Label column="sm">Zip</Form.Label>
                <Form.Control
                  value={state && state.address && state.address.zip_code}
                  size="sm"
                  name="zip_code"
                  onChange={handleInputAddress}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>

      <div className="row mt-3 pb-2 border-bottom">
        <div
          className="col-3 text-left text-muted d-none d-md-block"
          style={{ fontSize: 18, fontWeight: 500 }}
        >
          <span>Contact</span>
        </div>
        <div
          className="col-sm-12 col-md-9 text-left text-muted"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          <Form size="sm">
            <Row className="mb-2">
              <Form.Group className="mb-2 col-lg-6" controlId="formGridPhone1">
                <Form.Label column="sm">Phone-1</Form.Label>
                <Form.Control
                  value={state.phone1}
                  type="phone"
                  size="sm"
                  name="phone1"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-lg-6" controlId="formGridPhone2">
                <Form.Label column="sm">Phone-2</Form.Label>
                <Form.Control
                  value={state.phone2}
                  type="phone"
                  size="sm"
                  name="phone2"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Label column="sm" className="col-12">
                Social Media
              </Form.Label>
              {socials &&
                socials.map((social, i) => (
                  <Form.Group
                    key={i}
                    className="col-lg-8"
                    controlId="formGridSocial1"
                  >
                    <InputGroup className="mb-3">
                      <DropdownButton
                        size="sm"
                        variant="drop"
                        title="Social"
                        disabled={!isEditing}
                      >
                        {socialMedia.map((item, index) => {
                          return (
                            <Dropdown.Item
                              onClick={(e) => handleSelectSocial(e, i)}
                              name="name"
                              key={index}
                              value={item}
                              size="sm"
                            >
                              {item}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                      <InputGroup.Text>
                        <i
                          className={`fab fa-${
                            social.name === "stackoverflow"
                              ? "stack-overflow"
                              : social.name
                          }`}
                        ></i>
                      </InputGroup.Text>
                      <Form.Control
                        size="sm"
                        name="url"
                        //value={socials[i].url}
                        value={social.url}
                        onChange={(e) => handleInputSocial(e, i)}
                        disabled={!isEditing}
                      />
                    </InputGroup>
                  </Form.Group>
                ))}
            </Row>
          </Form>
        </div>
      </div>

      <div className="row mt-3 pb-2 ">
        <div
          className="col-3 text-left text-muted d-none d-md-block"
          style={{ fontSize: 18, fontWeight: 500 }}
        >
          <span>Additional</span>
        </div>
        <div
          className="col-sm-12 col-md-9 text-left text-muted"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          <Form size="sm">
            <Row className="mb-2">
              <Form.Group
                className="mb-2 col-lg-4"
                controlId="formGridBirthDate"
              >
                <Form.Label column="sm">Birth Date</Form.Label>
                <Form.Control
                  value={
                    state.birthDate
                      ? dateFormat(state.birthDate, "isoDate")
                      : ""
                  }
                  size="sm"
                  type="date"
                  name="birthDate"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-lg-4" controlId="formGridBirth">
                <Form.Label column="sm">Place of Birth</Form.Label>
                <Form.Control
                  value={state.placeOfBirth}
                  size="sm"
                  name="placeOfBirth"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>

              <Form.Group className="mb-2 col-lg-4" controlId="formGridNative">
                <Form.Label column="sm">Native Language</Form.Label>
                <Form.Control
                  value={
                    state.nativeLanguage !== "undefined"
                      ? state.nativeLanguage
                      : ""
                  }
                  size="sm"
                  name="nativeLanguage"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group className="col-lg-4" controlId="formGridCountry">
                <Form.Label column="sm">Country of Origin</Form.Label>
                <Form.Control
                  value={state.countryOrigin}
                  size="sm"
                  name="countryOrigin"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="col-lg-4" controlId="formGridRace">
                <Form.Label column="sm">Race</Form.Label>
                <Form.Control
                  value={state.race}
                  size="sm"
                  name="race"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group className="col-lg-4" controlId="formGridGender">
                <Form.Label column="sm">Gender</Form.Label>
                <Form.Control
                  value={state.gender}
                  size="sm"
                  name="gender"
                  onChange={handleInput}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}
