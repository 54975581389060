import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, Form, Button, Modal, Alert } from "react-bootstrap";
import { getUserContract, addNewContract } from "../actions/contract.action";
import UserContractDetail from "./UserContractDetail";
import dateFormat from "dateformat";

const initialContract = {
  contractname: "",
  company: "",
  salary: "",
  hireDate: "",
  endDate: "",
  employmentBasis: "",
  workMonths: "",
  position: "",
};

export default function UserContract(props) {
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const { current } = useSelector((store) => store.contracts);
  const [showModal, setshowModal] = useState(false);
  const [newcontract, setnewcontract] = useState(initialContract);
  const [isWorking, setisWorking] = useState(false);
  const [alert, setalert] = useState("");

  useEffect(() => {
    dispatch(getUserContract());
  }, [dispatch]);
  useEffect(() => {
    handleWorking();
  }, [state]);

  useEffect(() => {
    if (current) {
      setstate(current);
    }
  }, [current]);

  const handleInput = (e) => {
    setnewcontract({ ...newcontract, [e.target.name]: e.target.value });
  };
  const handleAddContract = (e) => {
    e.preventDefault();

    if (newcontract.endDate && newcontract.endDate < newcontract.hireDate) {
      setalert(
        "The end date you enter cannot be later than the start date of your contract!"
      );
      return;
    }
    if (state && state.length > 0 && state[0].endDate > newcontract.hireDate) {
      setalert(
        "The start date you enter cannot be earlier than the end date of your previous contract!"
      );
      return;
    }

    dispatch(addNewContract(newcontract)).then((status) => {
      if (status === 200) {
        setshowModal(false);
        setnewcontract(initialContract);
        dispatch(getUserContract());
      }
    });
  };

  const handleCheckDates = (edited) => {
    const index = state && state.findIndex((item) => item._id === edited._id);
    //console.log(index)

    if (state && state.length > 1) {
      if (index === 0) {
        if (state[index + 1].endDate > edited.hireDate) {
          return "The start date you enter cannot be earlier than the end date of your previous contract!";
        }
      } else if (index + 1 === state.length) {
        if (state[index - 1].hireDate < edited.endDate) {
          return "The end date you enter cannot be later than the start date of your next contract!";
        }
      } else {
        if (state[index + 1].endDate > edited.hireDate) {
          return "The start date you enter cannot be earlier than the end date of your previous contract!";
        } else if (state[index - 1].hireDate < edited.endDate) {
          return "The end date you enter cannot be later than the start date of your next contract!";
        }
      }
    }
  };

  const handleCancel = () => {
    setshowModal(false);
    setnewcontract(initialContract);
    setalert("")
  };

  function handleWorking() {
    //console.log("handle working calıştı")
    const sorted =
      state &&
      state.sort((a, b) => new Date(b.hireDate) - new Date(a.hireDate));
    // console.log(sorted)
    if (sorted) {
      if (
        (sorted && sorted[0] && sorted[0].endDate === "") ||
        (sorted && sorted[0] && sorted[0].endDate === null)
      ) {
        setisWorking(true);
      } else {
        setisWorking(false);
      }
    }
  }
  //console.log(state);
  //console.log(newcontract);
  return (
    <div className="pl-3 pr-3">
      <div
        className="editButtonGroup p-0 m-0 "
        style={{ display: `${isWorking ? "none" : "block"}` }}
      >
        <Button
          size="sm"
          variant="fw-bolder fs-7 me-2 py-2 px-3"
          className="position-relative btn1"
          style={{ top: "0px" }}
          onClick={() => {setshowModal(!showModal); setalert("")}}
        >
          Add Contract
        </Button>
      </div>
      <Modal
        show={showModal}
        onHide={handleCancel}
        color="danger"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddContract}>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Contract Name</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="contractname"
                  size="sm"
                  type="text"
                  value={newcontract.contractname}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Position</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="position"
                  size="sm"
                  type="text"
                  value={newcontract.position}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Contract Company</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="company"
                  size="sm"
                  type="text"
                  value={newcontract.company}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Start Date</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="hireDate"
                  size="sm"
                  type="date"
                  value={
                    newcontract.hireDate
                      ? dateFormat(newcontract.hireDate, "isoDate")
                      : ""
                  }
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">End Date</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="endDate"
                  size="sm"
                  type="date"
                  value={
                    newcontract.endDate
                      ? dateFormat(newcontract.endDate, "isoDate")
                      : ""
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Employment Basis</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="employmentBasis"
                  size="sm"
                  type="text"
                  value={newcontract.employmentBasis}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">
                  Contract Work Months
                </InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="workMonths"
                  size="sm"
                  type="text"
                  value={newcontract.workMonths}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Salary</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="salary"
                  size="sm"
                  type="text"
                  value={newcontract.salary}
                />
              </InputGroup>
            </Form.Group>
            <hr></hr>
            {alert && <Alert variant="warning">{alert}</Alert>}
            <div className="text-end">
              <Button
                type="submit"
                variant="fw-bolder fs-7 me-2 py-2 px-3"
                className="btn1"
              >
                Submit{" "}
              </Button>{" "}
              <Button
                variant="fw-bolder fs-7 me-2 py-2 px-3"
                className="btn1"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {state.map((contract, index) => (
        <div key={index}>
          <UserContractDetail
            handleCheckDates={handleCheckDates}
            contract={contract}
            setisWorking={setisWorking}
            {...props}
          />
        </div>
      ))}
    </div>
  );
}
