import React, { useState, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import dateFormat from "dateformat";

import {
  getUserContract,
  updateUserContract,
  deleteUserContract,
} from "../actions/contract.action";

const initialState = {
  user_id: "",
  contractname: "",
  company: "",
  salary: "",
  hireDate: "",
  endDate: "",
  employmentBasis: "",
  workMonths: "",
  position: "",
};

export default function UserContractDetail({ contract, setisWorking, handleCheckDates }) {
  const dispatch = useDispatch();
  const [isContracting, setisContracting] = useState(false);
  const [state, setstate] = useState(initialState);
  const [danger, setWarning] = useState(false);
  const [alert, setalert] = useState("")
  const [message, setmessage] = useState("")
  const [deleteMessage, setdeleteMessage] = useState("");
  const [deletealert, setdeletealert] = useState("")

  useEffect(() => {
    if (contract) {
      setstate(contract);
    }
  }, [contract]);

  const handleEditing = () => {
    setisContracting(!isContracting);
  };

  const handleClearEdit = () => {
    setisContracting(false);
    setstate(contract);
    setalert("")
  };

  const handleInput = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(state.endDate && (state.endDate < state.hireDate)){
      setalert("The end date you enter cannot be later than the start date of your contract!")
      return;
    }
    const res = handleCheckDates(state)
    if(res){
      setalert(res)
      return;
    }  
    
    dispatch(updateUserContract(state)).then((data) => {
      if (data.status === 200) {
        setisContracting(false);
        setalert("");
        setmessage(data.message);
        dispatch(getUserContract());
        if (state.endDate === "" || state.endDate === null) {
          setisWorking(true);
        } else if (state.endDate) {
          setisWorking(false);
        }
        setTimeout(() => {
          setmessage("");
        }, 2800);
      }else {
        setalert(data.message)
        setTimeout(() => {
          setalert("")
        }, 2800);
      }
    });
  };

  const handleDelete = () => {
    //console.log(state._id);
    dispatch(deleteUserContract(state._id)).then((data) => {
      if (data.status === 200) {
        setdeleteMessage(data.message)
        setTimeout(() => {
          setWarning(false);
          setdeleteMessage("")
          dispatch(getUserContract());
        }, 2800);
      } else {
        setdeletealert(data.message)
        setTimeout(() => {
          setalert("")
        }, 2800);
      }
    });
  };

  //console.log(props);
  //console.log(state);
  return (
    <div className="row mt-3 pb-2 border-bottom">
      <div
        className="col-3 text-left text-muted d-none d-md-block"
        style={{ fontSize: 18, fontWeight: 500 }}
      >
        <span>{state.contractname}</span>
      </div>
      <div
        className="col-sm-12 col-md-9 text-left text-muted"
        style={{ fontSize: 14, fontWeight: 500 }}
      > 
        <Form onSubmit={isContracting ? handleSubmit: ()=>{}}>
          <Row className="mb-2">
            
            <Form.Group className="mb-2 col-sm-12 d-flex justify-content-end">
            {
                (alert || message) && <Alert className="p-2 text-center w-75" variant={`${alert ? "warning" : "success"}`} >{alert}{message}</Alert>
              }
              <div className="editButtonGroup p-0 mb-2 ">
                <Button
                  size="sm"
                  type="button"
                  variant="fw-bolder fs-7 me-2 py-2 px-2"
                  onClick={isContracting ? handleClearEdit : handleEditing}
                  className={"btn1"}
                >
                  {isContracting ? "Discard" : "Edit"}
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">
                  Contract Name
                </InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="contractname"
                  size="sm"
                  type="text"
                  value={state.contractname}
                  disabled={!isContracting}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Company</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="company"
                  size="sm"
                  type="text"
                  value={state.company}
                  disabled={!isContracting}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Position</InputGroup.Text>
                <FormControl
                  onChange={handleInput}
                  name="position"
                  size="sm"
                  type="text"
                  value={state.position}
                  disabled={!isContracting}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Start Date</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="hireDate"
                  size="sm"
                  type="date"
                  value={dateFormat(state.hireDate, "isoDate")}
                  disabled={!isContracting}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">End Date</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="endDate"
                  size="sm"
                  type="date"
                  value={
                    state.endDate ? dateFormat(state.endDate, "isoDate") : ""
                  }
                  disabled={!isContracting}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Employment Basis</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="employmentBasis"
                  size="sm"
                  type="text"
                  value={state.employmentBasis}
                  disabled={!isContracting}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">
                  Contract Work Months
                </InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="workMonths"
                  size="sm"
                  type="text"
                  value={state.workMonths}
                  disabled={!isContracting}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-6">
              <InputGroup size="sm">
                <InputGroup.Text column="sm">Salary</InputGroup.Text>
                <Form.Control
                  onChange={handleInput}
                  name="salary"
                  size="sm"
                  type="text"
                  value={state.salary}
                  disabled={!isContracting}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 col-lg-4 col-sm-12 text-end">
              <div className="editButtonGroup  p-0 mb-2 ">
                <Button
                  size="sm"
                  variant="fw-bolder fs-7 me-2 py-2 px-2"
                  className="btn1"
                  type={isContracting ? "submit" : "button"}
                  onClick={isContracting ? undefined : () => setWarning(!danger)}
                >
                  {isContracting ? "Save" : "Delete"}
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Form>
        <Modal
          show={danger}
          onHide={() => setWarning(false)}
          color="danger"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure delete selected contract?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="fw-bolder fs-7 me-2 py-2 px-3"
              className="btn1"
              onClick={handleDelete}
              disabled={deleteMessage ? true : false}
            >
              Delete{" "}
            </Button>{" "}
            <Button
              variant="fw-bolder fs-7 me-2 py-2 px-3"
              className="btn1"
              onClick={() => setWarning(!danger)}
            >
              Cancel
            </Button>
          </Modal.Footer>
          {
                (deletealert || deleteMessage) && <div>
                  <Alert className="p-2 text-center" variant={`${deletealert ? "warning" : "success"}`} >{deletealert}{deleteMessage}</Alert>
                  </div>
              }
        </Modal>
      </div>
    </div>
  );
}
