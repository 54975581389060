import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";

import axios from "axios";
import { API_url } from "../config/config";
import Messages from "../components/Messages";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ResetPage = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1c262f";
  });

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  //const { message } = useSelector((state) => state);
  const [message, setMessage] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [isVisible, setIsVisible] = useState(true);


  //const [messageStatus, setMessageStatus] = useState(false);
  const dispatch = useDispatch();



  const handleReset = (e) => {
    e.preventDefault();
    setSuccessful(false)
    //     console.log(e.target.value);
    //console.log(email);
    axios
      .post(`${API_url}/api/password-reset`, { email })
      .then((res) => {
       // console.log(res);
       setSuccessful(true)
       setIsVisible(false)
       setMessage(res.data.message);
      })
      .catch((error) => {
         setSuccessful(false)
        //console.log(error.response.data.message);
        setMessage(error.response ? error.response.data.message : error.message);
      });
  };

  return (
    <div className="col-md-12">
      <div
        className="card card-container rounded mt-5"
        style={{ backgroundColor: "#212d37" }}
      >
        <img src="/images/logo_banner.png" alt="" className="mb-4 mt-4" />
        {isVisible ? (
              <Form onSubmit={handleReset} ref={form}>
              <div
                className="form-group fw-bolder mb-3"
                style={{ color: "#ef7d1a" }}
              >
                <label htmlFor="email" className="mb-2 text-center">
                  Email Adress
                </label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validations={[required]}
                  placeholder="Enter Your Email Adress"
                  
                />
              </div>
    
              <div className="form-group d-grid gap-2 my-3">
                <button
                  className="btn form-control-submit-button"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Send Password Reset Link</span>
                </button>
              </div>
              
              
              
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
        ) : (
          message && (
            <div className="form-group mt-2">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
              <Link className="mt-3 mb-1 float-end fw-bold" to="/">
            Return Home
          </Link>
            </div>
          )


        )}
        

        
      </div>
    </div>
  );
};

export default ResetPage;
