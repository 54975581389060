import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Redirect, Link } from "react-router-dom";
import { register } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const initialState = {
  email: "",
  firstname: "",
  middlename: "",
  lastname: "",
  phone: "",
};

const RegisterPage = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1c262f";
  });

  const form = useRef();
  const checkBtn = useRef();

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  //const [message, setMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const { message } = useSelector((state) => state);
  const dispatch = useDispatch();

  const onChangeFirstName = (e) => {
    const firstname = e.target.value;
    setFirstName(firstname);
  };
  const onChangeLastName = (e) => {
    const lastname = e.target.value;
    setLastName(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeConfirmPassword = (e) => {
    const conPassword = e.target.value;
    setConfirmPassword(conPassword);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (password !== confirmPassword) {
        setInfoMessage("Passwords do not match.");
        return;
      }
      if (password === "" && confirmPassword === "") {
        setInfoMessage(
          "You can not set your new password empty! Please check your  password"
        );
        return;
      }
      if (password === confirmPassword && password.length < 6) {
        setInfoMessage(
          "New password should be at least 6 character! Please check your  password"
        );
      }
      dispatch(register(firstname, lastname, email, password))
        .then(() => {
          setSuccessful(true);
          setIsVisible(true);
          setTimeout(() => {
            props.history.push("/");
          }, 3000);        
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
    <div className="col-md-12">
      <div
        className="card card-container mt-5"
        style={{ backgroundColor: "#212d37" }}
      >
        <img src="/images/logo_banner.png" alt="" className="mb-4 mt-4" />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div
                className="form-group"
                style={{ color: "#ef7d1a" }}
              >
                <label htmlFor="firstname" className="mb-1">
                  First name
                </label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  name="firstname"
                  value={firstname}
                  onChange={onChangeFirstName}
                  validations={[required]}
                />
              </div>

              <div
                className="form-group"
                style={{ color: "#ef7d1a" }}
              >
                <label htmlFor="lastname" className="mb-1">
                  Last name
                </label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  name="lastname"
                  value={lastname}
                  onChange={onChangeLastName}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="email"
                  className="mb-1"
                  style={{ color: "#ef7d1a" }}
                >
                  Email
                </label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="password"
                  className="fw-bolder mb-1"
                  style={{ color: "#ef7d1a" }}
                >
                  Password
                </label>
                <Input
                  type="password"
                  className="form-control mb-2"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="confirmPassword"
                  className="fw-bolder mb-1"
                  style={{ color: "#ef7d1a" }}
                >
                  Confirm Password
                </label>
                <Input
                  type="password"
                  className="form-control mb-2"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                  validations={[required, vpassword]}
                />
              </div>
              {infoMessage && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {infoMessage}
                  </div>
                </div>
              )}
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              <div className="form-group d-grid gap-2 my-3">
                <button className="btn">Sign Up</button>
              </div>
            </div>
          )}

          {isVisible && message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
              <Link className="mt-3 mb-1 float-end fw-bold" to="/">
                Return Home
              </Link>
            </div>
          )}

          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default RegisterPage;
