import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Table,
  Alert,
  Badge,
} from "react-bootstrap";
import { getNotifications, changeNoticeStatus, deleteNotification, changeUserStatus, sendUserConfirmation } from "../actions/admin.action";

export const NotificationList = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((store) => store.admins);
  const [state, setstate] = useState([]);
  const [message, setmessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  //console.log(notifications);
  useEffect(() => {
    const active =
      notifications && notifications.filter((item) => item.isActive === true);
    setstate(active);
  }, [notifications]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const handleActivate = (id, user_id, email) => {
      dispatch(changeUserStatus(user_id)).then((res) => {
        if (res.status === 200) {
          dispatch(changeNoticeStatus(id, false, "Activated"))
          dispatch(sendUserConfirmation(id, email))
          setmessage(res.msg);
          setTimeout(() => {
            dispatch(getNotifications());
            setmessage("");
          }, 2000);
        }
      });
    
  };
  
  const handleDismiss = (id) => {
    dispatch(changeNoticeStatus(id, false, "Rejected")).then((res) => {
      if (res.status === 200) {
        setmessage(res.msg);
        setTimeout(() => {
          dispatch(getNotifications());
          setmessage("");
        }, 2000);
      }
    });
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {message && <div className="position-fixed" style={{left:"10%"}}><Alert variant="success">{message}</Alert></div>}
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>#</th>
            {!isMobile && <th>Notification</th>}
            <th>Email</th>
            <th>Name</th>
            <th>Confirm</th>
            <th>Dismiss</th>
          </tr>
        </thead>
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  {!isMobile && <td>{item.notification_name}</td>}
                  <td>{item.email}</td>
                  <td>{item.firstname} {item.lastname}</td>
                  <td className="text-center">
                    
                    <Badge style={{cursor:"pointer"}} bg={"success"} onClick={() => handleActivate(item._id, item.user_id, item.email)}>
                      Activate User
                    </Badge>
                  </td>
                  <td className="text-center">
                    <Badge style={{cursor:"pointer"}} bg={"danger"} onClick={() => handleDismiss(item._id)}>
                      Reject Request
                    </Badge>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export const NotificationListP = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((store) => store.admins);
  const [state, setstate] = useState([]);
  const [message, setmessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  //console.log(notifications);
  useEffect(() => {
    const active =
      notifications && notifications.filter((item) => item.isActive === false);
    setstate(active);
  }, [notifications]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);


  const handleDelete = (id) => {
    dispatch(deleteNotification(id)).then((res) => {
      if (res.status === 200) {
        setmessage(res.msg);
        dispatch(getNotifications());
        setTimeout(() => {
          setmessage("");
        }, 2000);
      }
    });
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {message && <div className="position-fixed" style={{left:"10%"}}><Alert variant="success">{message}</Alert></div>}
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>#</th>
            {!isMobile && <th>Notification</th>}
            {!isMobile && <th>Email</th>}
            <th>Name</th>
            {!isMobile && <th>Created</th>}
            <th>Updated</th>
            <th>Status</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  {!isMobile && <td>{item.notification_name}</td>}
                  {!isMobile && <td>{item.email}</td>}
                  <td>{item.firstname} {item.lastname}</td>
                  {!isMobile && <td>{item.createdAt
                    ? new Date(item.createdAt).toLocaleDateString()
                    : ""}
                    </td>}
                  <td>{item.updatedAt
                    ? new Date(item.updatedAt).toLocaleDateString()
                    : ""}
                    </td>
                  <td>{item.status}</td>
                  <td className="text-center">
                    <span style={{cursor:"pointer"}} onClick={() => handleDelete(item._id)}>
                  <i className="fas fa-trash-alt"></i>
                  </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
